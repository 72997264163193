import styled from "@emotion/styled";

import { Delete, Photo } from "@mui/icons-material";
import { Avatar, IconButton, Stack, TextField } from "@mui/material";

type IconButtonProps = React.ComponentProps<typeof IconButton>;
export const StackButtonContainer = styled(Stack)({
  flexDirection: "row",
  gap: "1rem",
  padding: "2rem 0 1rem 0",
});

export const StyledAvatar = styled(Avatar)({
  width: "150px",
  height: "150px",
});

export const StyledIconButton = styled(IconButton)<IconButtonProps & { component: string }>({
  width: "150px",
  height: "150px",
});

export const StyledTextField = styled(TextField)({
  display: "none",
});
export const StyledDelete = styled(Delete)({
  paddingRight: "5px",
});

export const StyledPhoto = styled(Photo)({
  paddingRight: "5px",
});
