import { t } from "@lingui/macro";

export interface ValidatePasswordResult {
  oldPasswordError?: string;
  newPasswordError?: string;
  confirmPasswordError?: string;
}

interface ValidatePasswordParams {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const validatePassword = ({
  oldPassword,
  newPassword,
  confirmNewPassword,
}: ValidatePasswordParams): ValidatePasswordResult => {
  let confirmPasswordError = "";
  if (!confirmNewPassword.length) {
    confirmPasswordError = t`Confirm password is required`;
  } else if (newPassword !== confirmNewPassword) {
    confirmPasswordError = t`Passwords do not match`;
  }

  let newPasswordError = "";
  if (!newPassword.length) {
    newPasswordError = t`New password is required`;
  } else if (newPassword.length < 8) {
    newPasswordError = t`Password must be at least 8 characters long`;
  }

  let oldPasswordError = "";
  if (!oldPassword.length) {
    oldPasswordError = t`Old password is required`;
  } else if (oldPassword.length < 8) {
    oldPasswordError = t`Password must be at least 8 characters long`;
  }

  return {
    newPasswordError: newPasswordError,
    confirmPasswordError: confirmPasswordError,
    oldPasswordError: oldPasswordError,
  };
};
