import { t } from "@lingui/macro";
import { useState } from "react";
import { companyName } from "src/core/Url/url";
import { clearPhoneNumber } from "src/core/Utils/phoneNumbers";
import { AutoSaveIndicator } from "src/uiKit/AutoSaveIndicator/AutoSaveIndicator";
import { ImageForm } from "src/uiKit/ImageForm/ImageForm";
import { AutoSaveField } from "src/uiKit/Input/AutoSaveField";
import { Loader } from "src/uiKit/Loader/Loader";
import { useNotification } from "src/uiKit/Notification/useNotification";
import { TextAreaInput } from "src/uiKit/TextAreaInput/TextAreaInput";
import { COLORS } from "src/uiKit/assets/styles/colors";

import DoneIcon from "@mui/icons-material/Done";
import EmailIcon from "@mui/icons-material/Email";
import { Button, Stack, TextField, Typography } from "@mui/material";

import { UserInfo } from "@common/user.types";

import { uploadFile } from "../../Backend/uploadFile";
import { UserTextInfo } from "./style";

interface MyProfileFormProps {
  userInfo: UserInfo;
  myUserId: string;
  update: (userInfo: Partial<UserInfo>) => Promise<void>;
  onClose: () => void;
}

export function MyProfileForm({
  userInfo,
  myUserId,
  update,
  onClose,
}: MyProfileFormProps): JSX.Element {
  const { showNotification } = useNotification();

  const [imageLoading, setImageLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onDone = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    showNotification({
      message: t`User information saved successfully`,
      type: "success",
    });
    onClose();
  };

  const onChangeAvatar = async (file: File | null) => {
    let photoUrl = "";
    if (file) {
      setImageLoading(true);
      photoUrl = await uploadFile(`${companyName}/images/avatars/${myUserId}`, file);
      await update({
        photoURL: photoUrl,
      });
      setImageLoading(false);
    } else {
      await update({
        photoURL: "",
      });
    }
  };

  return (
    <form onSubmit={onDone}>
      <Stack
        sx={{
          gap: "20px",
        }}
      >
        <Typography variant="h3">{t`My Profile`}</Typography>

        <Stack sx={{ gap: "40px", paddingBottom: "0px" }}>
          <Stack
            sx={{
              gap: "40px",
              flexDirection: "row",
              "@media (max-width: 500px)": {
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              },
            }}
          >
            <Stack sx={{ width: "max-content" }}>
              {imageLoading ? (
                <Loader padding="60px 20px" />
              ) : (
                <ImageForm imageUrl={userInfo.photoURL || ""} onChange={onChangeAvatar} />
              )}
            </Stack>

            <Stack sx={{ gap: "15px", width: "100%" }}>
              <AutoSaveField
                value={userInfo.fullName || ""}
                onSave={async (newValue) =>
                  await update({
                    fullName: newValue,
                  })
                }
                setIsSaving={(isSaving) => setIsSaving(isSaving)}
                fieldComponent={({ value, onChange, onBlur, onFocus }) => (
                  <TextField
                    label={t`Full Name`}
                    fullWidth
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    placeholder={t`Enter your full name`}
                  />
                )}
              />

              <UserTextInfo variant="body2">
                {[userInfo.role, userInfo.grade].filter(Boolean).join(", ")}
              </UserTextInfo>

              <UserTextInfo variant="body2">
                <EmailIcon />
                {userInfo.email}
              </UserTextInfo>
            </Stack>
          </Stack>

          <Stack sx={{ gap: "20px" }}>
            <Stack
              sx={{
                flexDirection: "row",
                gap: "20px",
                justifyContent: "space-between",
                "@media (max-width: 500px)": {
                  flexDirection: "column",
                  gap: "20px",
                },
              }}
            >
              <AutoSaveField
                value={userInfo.phone || ""}
                onSave={async (newValue) =>
                  await update({
                    phone: newValue,
                  })
                }
                setIsSaving={(isSaving) => setIsSaving(isSaving)}
                fieldComponent={({ value, onChange, onBlur, onFocus }) => (
                  <TextField
                    label={t`Phone`}
                    type="tel"
                    name="phone"
                    fullWidth
                    value={value}
                    onChange={(e) => onChange(clearPhoneNumber(e.target.value))}
                    placeholder={""}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    id="contactPhoneNumber"
                  />
                )}
              />

              <AutoSaveField
                value={userInfo.linkedInUrl || ""}
                onSave={async (newValue) =>
                  await update({
                    linkedInUrl: newValue,
                  })
                }
                setIsSaving={(isSaving) => setIsSaving(isSaving)}
                fieldComponent={({ value, onChange, onBlur, onFocus }) => (
                  <TextField
                    label={"LinkedIn"}
                    value={value}
                    fullWidth
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={t`Enter your LinkedIn URL`}
                    onBlur={onBlur}
                    onFocus={onFocus}
                  />
                )}
              />
            </Stack>

            <AutoSaveField
              value={userInfo.about || ""}
              onSave={async (newValue) =>
                await update({
                  about: newValue,
                })
              }
              setIsSaving={(isSaving) => setIsSaving(isSaving)}
              fieldComponent={({ value, onChange, onBlur, onFocus }) => (
                <TextAreaInput
                  value={value}
                  onChange={(newValue) => onChange(newValue)}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  label={t`About me`}
                  maxCountSymbol={250}
                  placeholder={t`Provide short description about yourself`}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            bottom: "-10px",
            left: "0px",
            width: "100%",
            zIndex: 100,

            backgroundColor: "white",
            padding: "10px 0",
            "@media (max-width: 600px)": {
              borderTop: `1px solid ${COLORS.border}`,
            },
          }}
        >
          <AutoSaveIndicator isSaving={isSaving} />
          <Button startIcon={<DoneIcon />} variant="contained" type="submit">
            {t`Done`}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
