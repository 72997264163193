import styled from "@emotion/styled";

const contentStyle = {
  fontFamily: "var(--font-family)",
  fontSize: "1rem",
  ul: {
    paddingLeft: "20px",
    paddingBottom: "20px",
    margin: 0,
  },
  li: {
    paddingLeft: 0,
  },
  ".ql-size-huge": {
    fontSize: "1.7rem",
  },
  h2: {
    fontSize: "1.4rem",
  },
  ".ql-size-large": {
    fontSize: "1.2rem",
  },
  ".ql-size-small": {
    fontSize: "0.7rem",
  },
  p: {
    margin: "0 0 10px 0",
    padding: 0,
  },
  img: {
    maxWidth: "30px",
  },
};

export const EditorContainer = styled.div<{ limited?: boolean; maxHeight?: string }>(
  {
    display: "flex",
    flexDirection: "column",
    gap: "10px",

    ".rich-editor-content": {
      padding: "3px 15px",
      minHeight: "70px",
      minWidth: "100%",
      fontFamily: "var(--font-family)",
    },
    ".rich-editor-content:empty": {
      padding: "19px 15px 0px 15px",
    },
  },
  ({ limited, maxHeight }) => ({
    width: limited ? "100%" : "calc(100% - 35px)",
    maxHeight: maxHeight || "60vh",
    overflow: "auto",
    img: {
      maxWidth: "100%",
    },
  }),
);

export const ViewContainer = styled.div({
  ...contentStyle,
});
