import * as React from "react";
import { stringToColor } from "src/core/Utils/stringToColor";

import { SxProps, Theme } from "@mui/material";
import Avatar from "@mui/material/Avatar";

function stringAvatar(name: string) {
  const nameParts = name.split(" ");
  const nameLetters = nameParts.filter((_, index) => index < 2).map((part) => part[0]);

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: nameLetters,
  };
}

export interface ColorTextAvatarProps {
  name: string;
  src: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

export default function ColorTextAvatar({ name, src, sx, onClick }: ColorTextAvatarProps) {
  const clickStyle = onClick
    ? {
        cursor: "pointer",
        ":hover": {
          boxShadow: "0 0 0 1px white, 1px 1px 3px #666",
        },
      }
    : {};

  if (src) {
    return <Avatar alt={name} src={src} sx={{ ...sx, ...clickStyle }} onClick={onClick} />;
  }

  if (!name) {
    return <Avatar sx={sx} onClick={onClick} />;
  }

  const colorProps = stringAvatar(name);
  const style = {
    ...sx,
    ...colorProps.sx,
    ...clickStyle,
  };

  return <Avatar {...stringAvatar(name)} sx={style} onClick={onClick} />;
}
