import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { Loader } from "src/uiKit/Loader/Loader";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import StorageIcon from "@mui/icons-material/Storage";
import { Button, Stack } from "@mui/material";

import {
  DeleteDatabaseResponse,
  GenerateDataBaseResponse,
  GenerateDatabaseProps,
} from "../../../../server/src/api/devTools/devTools";
import { useAuth } from "../Auth/useAuth";
import { functions } from "../Backend";
import { TEST_SELECTOR, getTestDataIdAttribute } from "../Tests/selectors";
import { redirectToSignInPage } from "../Url/url";
import { DevButtonComponent } from "./style";

const COMPANY_DOMAIN_NAME = "vertigo";
const ADMIN_EMAIL = "admin@vertigo.com";
const ADMIN_PASSWORD = "123123FF!!";

const USER_EMAIL = "user@vertigo.com";
const USER_PASSWORD = "123123FF!!";

const CLIENT_EMAIL = "client@vertigo.com";
const CLIENT_PASSWORD = "123123FF!!";

const resetDatabase = httpsCallable<unknown, DeleteDatabaseResponse>(
  functions,
  "devTool-deleteDatabase",
);

const generateDatabaseFunction = httpsCallable<GenerateDatabaseProps, GenerateDataBaseResponse>(
  functions,
  "devTool-generateDatabase",
);

export function DevButton() {
  const { user, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [usersGenerated, setUsersGenerated] = useState(false);

  const loadingComponent = (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: 0,
        top: 0,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        pointerEvents: "none",
        zIndex: 999,
      }}
    >
      <Loader />
    </div>
  );

  const deleteDatabase = async () => {
    setError("");
    setLoading(true);
    try {
      const resultOfRequest = await resetDatabase();
      setLoading(false);
      logout();
      const { error } = resultOfRequest.data;
      if (error) {
        setError(error);
      }
    } catch (e) {
      setError(JSON.stringify(e));
    }
    setLoading(false);
  };

  const generateUsers = async () => {
    setUsersGenerated(false);
    setError("");
    setLoading(true);
    try {
      const resultOfRequest = await generateDatabaseFunction({
        domain: COMPANY_DOMAIN_NAME,
        adminEmail: ADMIN_EMAIL,
        adminPassword: ADMIN_PASSWORD,
        userEmail: USER_EMAIL,
        userPassword: USER_PASSWORD,
      });
      const { error } = resultOfRequest.data;

      if (error) {
        setError(error);
      }
    } catch (e) {
      setError(JSON.stringify(e));
    }
    setLoading(false);
    setUsersGenerated(true);
  };

  const signAsUser = async () => {
    if (user) {
      logout();
    }
    redirectToSignInPage(
      {
        domain: COMPANY_DOMAIN_NAME,
        password: USER_PASSWORD,
        email: USER_EMAIL,
      },
      { forceRedirect: true },
    );
  };

  const signAsClient = async () => {
    if (user) {
      logout();
    }
    redirectToSignInPage(
      {
        domain: COMPANY_DOMAIN_NAME,
        password: CLIENT_PASSWORD,
        email: CLIENT_EMAIL,
      },
      { forceRedirect: true },
    );
  };

  const signAsAdmin = async () => {
    if (user) {
      logout();
    }

    redirectToSignInPage(
      {
        domain: COMPANY_DOMAIN_NAME,
        password: ADMIN_PASSWORD,
        email: ADMIN_EMAIL,
      },
      { forceRedirect: true },
    );
  };

  return (
    <DevButtonComponent
      {...getTestDataIdAttribute(TEST_SELECTOR.devTool.mainTogglerButton)}
      data-users-generated={usersGenerated}
      data-dev-panel-loading={loading}
    >
      <div className="dev-controls">
        <Stack
          justifyContent={"space-between"}
          sx={{
            height: "100%",
          }}
        >
          <Stack gap={"20px"}>
            <Button
              onClick={() => deleteDatabase()}
              variant="outlined"
              startIcon={<DeleteIcon />}
              {...getTestDataIdAttribute(TEST_SELECTOR.devTool.resetButton)}
            >
              Delete DB
            </Button>
            <Button variant="outlined" onClick={() => signAsUser()} startIcon={<PersonIcon />}>
              SingIn (user)
            </Button>
          </Stack>

          <Stack gap={"20px"}>
            {loading && loadingComponent}
            <p style={{ color: "tomato" }}>{error ? "Error: " + error : ""}</p>

            <Button
              onClick={() => generateUsers()}
              variant="outlined"
              sx={{ height: "120px" }}
              startIcon={<StorageIcon />}
              {...getTestDataIdAttribute(TEST_SELECTOR.devTool.generateButton)}
            >
              Generate DB
            </Button>

            <Button
              sx={{ height: "60px" }}
              onClick={() => signAsClient()}
              variant="contained"
              startIcon={<PersonIcon />}
            >
              Client
            </Button>

            <Button
              {...getTestDataIdAttribute(TEST_SELECTOR.devTool.adminLoginButton)}
              onClick={() => signAsAdmin()}
              variant="contained"
              sx={{ height: "140px" }}
              startIcon={<AdminPanelSettingsIcon />}
            >
              Admin
            </Button>
          </Stack>
        </Stack>
      </div>
    </DevButtonComponent>
  );
}
