import * as Sentry from "@sentry/react";
import { User, signOut } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { Context, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import db from "src/core/Backend/firestore";

import { auth } from "../Backend";
import { companyName, getFullUrlWithDomain, isExtendedUrl } from "../Url/url";

interface AuthContext {
  loading: boolean;
  isSuperAdmin: boolean;
  uid: string;
  userInfo: {
    displayName: string | null;
    email: string | null;
    phoneNumber: string | null;
    photoURL: string | null;
  };
  user: User | null;
  isAuthorized: boolean;
  logout: () => Promise<void>;
  redirectToSignIn: () => Promise<void>;
  redirectToRootPage: () => Promise<void>;
}

const authContext: Context<AuthContext> = createContext<AuthContext>({
  loading: true,
  uid: "",
  isAuthorized: false,
  userInfo: {
    phoneNumber: "",
    email: "",
    photoURL: "",
    displayName: "",
  },
  user: null,
  redirectToSignIn: async () => void 0,
  redirectToRootPage: async () => void 0,
  logout: async () => void 0,
  isSuperAdmin: false,
});

function useProvideAuth(): AuthContext {
  const [user, loading, errorAuth] = useAuthState(auth);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const [isValidUser, setIsValidUser] = useState(true);

  useEffect(() => {
    if (user) {
      user.getIdTokenResult().then(({ claims }) => {
        const isSuperAdmin = !!claims?.isSuperAdmin;
        setIsSuperAdmin(isSuperAdmin);

        if (!isSuperAdmin) {
          const userDomains = claims?.domains as string[] | null;
          if (userDomains) {
            if (userDomains.includes(companyName || "")) {
              setIsValidUser(true);
            } else {
              setIsValidUser(false);
              logout();
              alert(
                `You are not authorized to use this company account. You will be redirected to ${userDomains[0]}`,
              );
              const urlToRedirect = getFullUrlWithDomain(userDomains[0]);
              setTimeout(() => {
                window.location.href = urlToRedirect;
              }, 200);
              return;
            }
          } else {
            setIsValidUser(false);
            logout();
            alert("You are not authorized to use this company account");
            return;
          }
          saveLastLogin(user.uid);
        }
      });
    }
  }, [user, user?.uid]);

  useEffect(() => {
    if (user?.uid && companyName === "auth") {
      const urlString = window.location.href;
      Sentry.captureException(new Error("User is authorized on weird space"), {
        data: { user: user.uid, companyName, email: user.email, urlString },
      });

      setTimeout(() => {
        auth.signOut();
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 200);
      }, 200);
    }
  }, [user?.uid]);

  const navigate = useNavigate();
  const isAuthorized = !!user?.uid && !errorAuth;

  const userInfo = {
    email: user?.email || "",
    photoURL: user?.photoURL || "",
    displayName: user?.displayName || "User",
    uid: user?.uid || "",
    phoneNumber: user?.phoneNumber || "",
    providerId: user?.providerId || "",
  };

  const saveLastLogin = async (userId: string): Promise<void> => {
    setTimeout(async () => {
      const time = Date.now();
      const userDoc = doc(db.collections.users, userId);
      const lastLoginCompanyName = companyName || "";
      await updateDoc(userDoc, { lastLoginAt: time, lastLoginCompanyName });
    }, 500);
  };

  const logout = async (): Promise<void> => {
    await signOut(auth);
    await redirectToSignIn();
    setIsValidUser(false);
  };

  const redirectToSignIn = async (): Promise<void> => navigate("/sign-in");
  const redirectToRootPage = async (): Promise<void> => navigate("/");

  return {
    isSuperAdmin,
    isAuthorized: isValidUser && isAuthorized,
    userInfo,
    uid: isValidUser ? user?.uid || "" : "",
    loading,
    redirectToSignIn,
    redirectToRootPage,
    logout,
    user: isValidUser ? user || null : null,
  };
}

export function AuthProvider(props: { children: ReactNode }): JSX.Element {
  const auth = useProvideAuth();
  const location = useLocation();
  const componentForShow = props.children;

  const staticPages = [
    "/contacts",
    "/parser/public",
    "/examples",
    "/redirect",
    "/terms-of-service",
    "/privacy-policy",
    "/portfolio/",
    "/git-summary",
    "/sharedLink/",
  ];
  const authPages = [
    "/sign-in",
    "/sign-up-create-account",
    "/reset-password",
    "/request-reset-password",
    "/confirm-company",
    "/accept-invite",
  ];

  const pathname = location.pathname;
  const isStaticPage = staticPages.find((path) => pathname.startsWith(path));
  const isAuthPage = authPages.find((path) => pathname.startsWith(path));
  const isDesignSystem = pathname.startsWith("/design");

  const needToRedirectToAuthPage = !isAuthPage && !isStaticPage && !isDesignSystem;

  useEffect(() => {
    if (!auth.loading && !auth.isAuthorized && needToRedirectToAuthPage) {
      setTimeout(() => {
        if (isExtendedUrl) {
          auth.redirectToSignIn();
        } else {
          auth.redirectToRootPage();
        }
      }, 0);
    }
  }, [isStaticPage, isAuthPage, auth.isAuthorized, auth.loading]);

  if (auth.loading) {
    return <></>;
  }

  return <authContext.Provider value={auth}>{componentForShow}</authContext.Provider>;
}

export const useAuth = (): AuthContext => useContext(authContext);
