// Example of using Converter:
// T56g4.gf1h456.456.jpeg ==> T56g4.g ... .jpeg

export function makeFileNameShorter(fileName: string) {
  const position = fileName.lastIndexOf(".");
  let filename = fileName.slice(0, position);
  const extension = fileName.slice(position);
  if (filename.length > 11) {
    filename = filename.substring(0, 9 - extension.length + 3);
    return filename + " ... " + extension;
  }
  return filename + extension;
}
