import { CustomModal } from "src/uiKit/Modal/CustomModal";

import LanguageForm from "./LanguageForm";

interface LanguageSettingModalProps {
  onClose: () => void;
}

export function LanguageSettingModal({ onClose }: LanguageSettingModalProps): JSX.Element {
  return (
    <CustomModal onClose={onClose}>
      <LanguageForm onClose={onClose} />
    </CustomModal>
  );
}
