import { t } from "@lingui/macro";
import { FC, useState } from "react";
import RichTextViewer from "src/uiKit/RichText/RichTextViewer";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Link, Typography } from "@mui/material";

interface MessageContentProps {
  message: string;
  isRichText?: boolean;
  maxSymbols?: number;
}

export const MessageContent: FC<MessageContentProps> = ({ message, isRichText, maxSymbols }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isNeedShowMore = !isExpanded && maxSymbols && message.length > maxSymbols;

  const transformedMessage = isNeedShowMore ? message.slice(0, maxSymbols) + "..." : message;
  return (
    <div>
      {isRichText ? (
        <RichTextViewer value={message} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            color: COLORS["text-label"],
          }}
        >
          {transformedMessage}
          {isNeedShowMore && (
            <Link
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsExpanded(true);
              }}
              sx={{
                paddingLeft: "10px",
              }}
            >
              {t`Show more`}
            </Link>
          )}
        </Typography>
      )}
    </div>
  );
};
