import { FC, ReactNode } from "react";

import { Typography } from "@mui/material";

export const PageTitle: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography variant="h2" component="h2">
      {children}
    </Typography>
  );
};
