import { useLocalStorage } from "@uidotdev/usehooks";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";

export const useAiSearchColumnSettings = (vacancyId: string) => {
  const users = useUsers();
  const [aiSearchStorage, setAiSearchStorage] = useLocalStorage<Record<string, boolean>>(
    "aiSearchHidden",
    {},
  );

  const setAiSearchHidden = (hidden: boolean) =>
    setAiSearchStorage((prevState) => ({ ...prevState, [vacancyId]: hidden }));

  return {
    isAiSearchHidden: users.isClientForHiring || aiSearchStorage[vacancyId],
    setAiSearchHidden,
    isCanSeeAiSearch: !users.isClientForHiring,
  };
};
