import { Context, ReactNode, createContext, useContext, useState } from "react";
import { COLORS } from "src/uiKit/assets/styles/colors";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogTitle } from "@mui/material";

interface showDialogProps {
  title: string;
  content: JSX.Element;
  closeHandler?: () => void;
}

interface DialogContext {
  showDialog: (options: showDialogProps) => void;
  closeDialog: () => void;
}

const dialogContext: Context<DialogContext> = createContext<DialogContext>({
  showDialog: () => void 0,
  closeDialog: () => void 0,
});

const defaultDialogTitle = "Dialog";

export function DialogProvider(props: { children: ReactNode }): JSX.Element {
  const [isOpen, setOpenState] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(defaultDialogTitle);
  const [closeHandler, setCloseHandler] = useState<() => void>();
  const [dialogContent, setDialogContent] = useState<JSX.Element>(<></>);
  const closeDialog = () => {
    setOpenState(false);
  };

  const showDialog = ({ content, title, closeHandler }: showDialogProps) => {
    setDialogTitle(title || defaultDialogTitle);
    setCloseHandler(() => closeHandler);
    setDialogContent(content);
    setOpenState(true);
  };

  const dialogComponent = (
    <Dialog
      onClose={() => {
        closeDialog();
        closeHandler && closeHandler();
      }}
      open={isOpen}
    >
      <CloseIcon
        sx={{
          position: "absolute",
          top: 15,
          right: 15,
          color: COLORS["text-disabled"],
          cursor: "pointer",
        }}
        onClick={closeDialog}
      />
      <DialogTitle>{dialogTitle}</DialogTitle>
      <Box sx={{ padding: "0 23px 20px 23px" }}>{dialogContent}</Box>
    </Dialog>
  );

  const value = { showDialog, closeDialog };
  return (
    <dialogContext.Provider value={value}>
      {props.children}
      {dialogComponent}
    </dialogContext.Provider>
  );
}

export const useDialog = (): DialogContext => useContext(dialogContext);
