import { lazy } from "react";
import { Route } from "react-router-dom";

const SystemRolesListPate = lazy(() => import("./SystemRolesListPage"));
const SystemRolesEditor = lazy(() => import("./SystemRolesEditor"));

export const systemRolesRouter = (
  <>
    <Route path="/system-roles" element={<SystemRolesListPate />} />
    <Route path="/system-roles/:roleId" element={<SystemRolesEditor />} />
  </>
);
export default systemRolesRouter;
