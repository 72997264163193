import { Markdown } from "./Markdown";

interface RichTextViewerProps {
  value: string;
}

export default function RichTextViewer({ value }: RichTextViewerProps) {
  const cleanValue = value.replaceAll("&#x20;", " ");
  return <Markdown>{cleanValue}</Markdown>;
}
