import { lazy } from "react";
import { Route } from "react-router-dom";

const HiringPipelinePage = lazy(() => import("./HiringPipelinePage"));
const HiringPipelineListPage = lazy(() => import("./HiringPipelineListPage"));

export const HiringPipelineRouter = (
  <>
    <Route path="/hiring-pipeline/:id" element={<HiringPipelinePage />} />
    <Route path="/hiring-pipeline" element={<HiringPipelineListPage />} />
  </>
);
