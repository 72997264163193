import { GetTypeOfProperty } from "src/core/Utils/type.utils";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Components, ComponentsOverrides, ComponentsVariants } from "@mui/material";

const cardStyleOverrides: ComponentsOverrides["MuiCard"] = {
  root: {
    borderRadius: "24px",
    boxShadow: "none",
    backgroundColor: COLORS["bg-card"],
  },
};

const paperVariants: ComponentsVariants["MuiPaper"] = [
  {
    props: { variant: "outlined" },
    style: {},
  },
  {
    props: { variant: "elevation" },
    style: {},
  },
];

const paperStyleOverrides: ComponentsOverrides["MuiPaper"] = {
  root: {
    borderRadius: "24px",
    boxShadow: "none",
    backgroundColor: COLORS["bg-card"],
  },
};

export const customCard: GetTypeOfProperty<Components, "MuiCard"> = {
  styleOverrides: cardStyleOverrides,
};
export const customPaper: GetTypeOfProperty<Components, "MuiPaper"> = {
  styleOverrides: paperStyleOverrides,
  variants: paperVariants,
};
