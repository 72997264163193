import { isExtendedUrl } from "../Url/url";
import BaseHeader, { HeaderMenuElement } from "./BaseHeader";

export default function StaticHeader(): JSX.Element {
  const menuItems: HeaderMenuElement[] = [];

  if (isExtendedUrl) {
    menuItems.push({ linkTo: "/sign-in", title: "Sign In", type: "Tab" });
  } else {
    menuItems.push({
      linkTo: "/sign-up-create-account",
      title: "Sign up",
      type: "Tab",
    });

    menuItems.push({
      title: "Sign In",
      linkTo: "/sign-in",
      type: "EmptyButton",
    });
  }

  return <BaseHeader rightHeaderMenuItems={menuItems} viewBreakpointWidth={870} />;
}
