import styled from "@emotion/styled";
import { COLORS } from "src/uiKit/assets/styles/colors";

export const CommentContainer = styled("div")({
  display: "flex",
  flexDirection: "row",

  padding: "15px 20px 22px 20px",
  borderRadius: "5px",
  gap: "20px",
  ":hover": {
    backgroundColor: COLORS["bg-card"],
  },
});

export const SendMessageForm = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr max-content",
  gap: "10px",
  alignItems: "flex-start",
});
