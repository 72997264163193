import React from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";

import { customAutocomplete } from "./Autocomplete/style";
import { customAvatar } from "./Avatar/avatar.style";
import { customButton } from "./Button/style";
import { customCard, customPaper } from "./Cards/style";
import { customSwitch } from "./Checkbox/style";
import { customDrawer } from "./Drawer/style";
import { customChip } from "./Input/MultiValueInput/style";
import { customHelperText } from "./Input/formHelperText.style";
import { customFormLabel } from "./Input/formLabel.style";
import { customInput } from "./Input/input.style";
import { customInputLabel } from "./Input/inputLabel.style";
import { customOutlinedInput } from "./Input/outlinedInput.style";
import { customTextField } from "./Input/textField.style";
import { customCircularProgress } from "./Loader/style";
import { customMenu } from "./Menu/style";
import { customPopover, customPopper } from "./Popover/style";
import { customLink, customTypography } from "./Typography/style";
import { COLORS_CSS_DECLARATION } from "./assets/styles/colors";

const customPalette: PaletteOptions = {
  primary: {
    main: "#1068EB",
    light: "#1068EB",
    dark: "#225ebd",
  },
};

export const ThemeContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = createTheme({
    palette: customPalette,
    components: {
      MuiTypography: customTypography,
      MuiLink: customLink,
      MuiButton: customButton,
      MuiInputBase: customInput,
      MuiFormHelperText: customHelperText,
      MuiFormLabel: customFormLabel,
      MuiTextField: customTextField,
      MuiOutlinedInput: customOutlinedInput,
      MuiInputLabel: customInputLabel,
      MuiAvatar: customAvatar,
      MuiCircularProgress: customCircularProgress,
      MuiSwitch: customSwitch,
      MuiAutocomplete: customAutocomplete,
      MuiChip: customChip,
      MuiCard: customCard,
      MuiPaper: customPaper,
      MuiMenu: customMenu,
      MuiDrawer: customDrawer,
      MuiPopover: customPopover,
      MuiPopper: customPopper,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <style>{COLORS_CSS_DECLARATION}</style>
      {children}
    </ThemeProvider>
  );
};
