import { Trans, t } from "@lingui/macro";
import { doc, setDoc } from "firebase/firestore";
import { InfoCircle } from "iconsax-react";
import { FC, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import db from "src/core/Backend/firestore";
import { AccessForm } from "src/prohiring/AccessForm/AccessForm";
import { AutoSaveIndicator } from "src/uiKit/AutoSaveIndicator/AutoSaveIndicator";
import { COLORS } from "src/uiKit/assets/styles/colors";

import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { Alert, Button, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";

import { CandidateAccess } from "@common/vacancy.types";

interface AccessSettingsProps {
  vacancyId: string;
}
export const AccessSettings: FC<AccessSettingsProps> = ({ vacancyId }) => {
  const [vacancy] = useDocumentData(doc(db.collections.vacancies, vacancyId));

  const [selectedStepOnAccessSettings, setSelectedStepOnAccessSettings] = useState<string>("");
  const vacancySteps = vacancy?.hiringCircle.map((step) => step.title) || [];

  const vacancyAccessLevels = vacancy?.vacancyAccessLevels || [];
  const stepAccessLevels =
    vacancy?.hiringCircleAccessLevels?.[selectedStepOnAccessSettings] ||
    vacancy?.vacancyAccessLevels ||
    [];
  const isSpecificAccess = !!vacancy?.hiringCircleAccessLevels?.[selectedStepOnAccessSettings];

  const [isVacancyAccessSettingsSaving, setIsVacancyAccessSettingsSaving] = useState(false);
  const setVacancyAccess = async (newAccessLevel: CandidateAccess[]) => {
    const document = doc(db.collections.vacancies, vacancyId);
    setIsVacancyAccessSettingsSaving(true);
    await setDoc(document, { vacancyAccessLevels: newAccessLevel }, { merge: true });
    setIsVacancyAccessSettingsSaving(false);
  };

  const [isStepAccessSettingsSaving, setIsStepAccessSettingsSaving] = useState(false);
  const setHiringCircleAccess = async (step: string, newAccess: CandidateAccess[]) => {
    if (!vacancy) return;

    const document = doc(db.collections.vacancies, vacancyId);
    setIsStepAccessSettingsSaving(true);
    await setDoc(
      document,
      { hiringCircleAccessLevels: { ...vacancy.hiringCircleAccessLevels, [step]: newAccess } },
      { merge: true },
    );
    setIsStepAccessSettingsSaving(false);
  };

  const deleteHiringCircleAccess = (step: string) => {
    if (!vacancy) return;
    const newAccess = { ...vacancy.hiringCircleAccessLevels };
    delete newAccess[step];

    const document = doc(db.collections.vacancies, vacancyId);
    setDoc(document, { hiringCircleAccessLevels: newAccess }, { merge: true });
  };

  const isInternalVacancyType = vacancy?.clientType === "Internal";

  if (isInternalVacancyType) {
    return (
      <Stack
        gap={"20px"}
        sx={{
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h3">{t`Vacancy access settings`}</Typography>

        <Alert severity="info">
          <Typography
            variant="caption"
            sx={{
              color: COLORS["text-secondary"],
            }}
          >
            {t`As an internal vacancy, access settings are not available. All users can view all candidates.`}
          </Typography>
        </Alert>
        <Button
          startIcon={<EditIcon />}
          target="_blank"
          href={`/vacancy-editor/${vacancyId}?edit=true`}
          variant="outlined"
        >
          {t`Edit vacancy`}
        </Button>
      </Stack>
    );
  }

  return (
    <Stack
      direction={"column"}
      gap={"40px"}
      sx={{
        width: "100%",
      }}
    >
      <Stack
        gap={"0px"}
        sx={{
          width: "100%",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"10px"} flexWrap={"wrap"}>
          <Typography variant="h3">{t`Vacancy access settings`}</Typography>
          <AutoSaveIndicator isSaving={isVacancyAccessSettingsSaving} />
        </Stack>
        <Typography
          variant="caption"
          sx={{
            lineHeight: "1.2",
            color: COLORS["text-secondary"],
          }}
        >
          {t`Define what information clients can view about candidates for this vacancy.`}
        </Typography>
        <AccessForm setAccessLevels={setVacancyAccess} accessLevels={vacancyAccessLevels} />
      </Stack>

      <Stack gap={"0px"}>
        <Stack direction={"row"} alignItems={"center"} gap={"10px"} flexWrap={"wrap"}>
          <Typography variant="h3">{t`Step access settings`}</Typography>
          <Tooltip
            title={
              <Typography variant="caption">
                <Trans>
                  Step access settings determine what clients can view about candidates at this
                  particular stage of the recruitment process. These settings take precedence over
                  vacancy-wide access settings. For even more granular control, you can set
                  individual access rules <b>for each candidate</b>, which will supersede these step
                  settings.
                </Trans>
              </Typography>
            }
          >
            <InfoCircle size="18" />
          </Tooltip>

          <AutoSaveIndicator isSaving={isStepAccessSettingsSaving} />
        </Stack>

        <Typography
          variant="caption"
          sx={{
            lineHeight: "1.2",
            marginBottom: "15px",
            color: COLORS["text-secondary"],
          }}
        >
          {t`You can specify access settings for each step.`}
        </Typography>

        <Select
          value={selectedStepOnAccessSettings}
          onChange={(e) => setSelectedStepOnAccessSettings(e.target.value as string)}
        >
          {vacancySteps.map((step) => (
            <MenuItem key={step} value={step}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                gap={"10px"}
              >
                <Typography>{step}</Typography>

                {!!vacancy?.hiringCircleAccessLevels?.[step] && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: COLORS["primary"],
                    }}
                  >
                    {t`Custom`}
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          {t`This step has custom access settings.`}
                        </Typography>
                      }
                    >
                      <InfoIcon
                        sx={{
                          fontSize: "1rem",
                          paddingLeft: "4px",
                          marginBottom: "-3px",
                          color: COLORS["text-secondary"],
                        }}
                      />
                    </Tooltip>
                  </Typography>
                )}
              </Stack>
            </MenuItem>
          ))}
        </Select>
        {selectedStepOnAccessSettings ? (
          <Stack>
            <AccessForm
              setAccessLevels={(value) =>
                setHiringCircleAccess(selectedStepOnAccessSettings, value)
              }
              accessLevels={stepAccessLevels}
            />
            {isSpecificAccess && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{
                  padding: "10px 0",
                }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => deleteHiringCircleAccess(selectedStepOnAccessSettings)}
                >
                  {t`Reset this step`}
                </Button>
              </Stack>
            )}
          </Stack>
        ) : (
          <Typography variant="caption"></Typography>
        )}
      </Stack>
    </Stack>
  );
};
