export const isImageName = (name: string) => {
  return (
    name.endsWith(".png") ||
    name.endsWith(".svg") ||
    name.endsWith(".jpg") ||
    name.endsWith(".gif") ||
    name.endsWith(".jpeg") ||
    name.endsWith(".webp")
  );
};
