const baseThemeColors = {
  // Interactive colors
  primary: "#1068EB",
  secondary: "#303D60",

  // Background Color
  bg: "#eef2f3",
  "bg-card": "#FFFFFF",
  "bg-input": "#FFFFFF",
  "bg-input-disabled": "#EEF1F4",
  "bg-overlay": "rgba(39, 43, 48, 0.6)",
  "bg-row": "#F8F9FA",
  "bg-row-light": "#FFFFFF",
  "bg-notification": "#303D60",

  // Text Colors
  text: "#101213", // Dark text color
  "text-secondary": "#6A7178", // Light text color
  "text-label": "#4F575E",
  "text-placeholder": "#6A7178",
  "text-disabled": "#ADB5BD",
  link: "#105FD5",
  "link-hovered": "#303D60",
  "text-contrast": "#ffffff",

  // Icon Colors
  icon: "#6A7178",

  // Functional Colors
  error: "#DC2020",
  success: "#29823B",
  warning: "#E9A100",

  // Borders & Dividers
  border: "#CED4DA",
  "border-focus": "#1068EB",
  divider: "#CED4DA",

  // Portfolio
  "portfolio-gradient": "linear-gradient(95.93deg, #E5F0FF 0%, #CFE2FF 100%)",
  "portfolio-cards-hovered": "#E8F4FF",
  "portfolio-cards-pressed": "#A8CAED",
};

type ColorsName = keyof typeof baseThemeColors;
const colorKeys = Object.keys(baseThemeColors).map((key) => key as ColorsName);

const darkThemeColors: { [color in ColorsName]: string } = {
  ...baseThemeColors,
  "text-secondary": "#FFFFFF",
  link: "#105fd5",
  "text-contrast": "#1A1E2B",
  "text-label": "#CED4DA",
  text: "#FFFFFF",
  "bg-card": "#272b30",

  // Portfolio
  "portfolio-gradient": "linear-gradient(95.93deg, #4F688D 0%, #284572 100%)",
  "portfolio-cards-pressed": "#50657C",
  "portfolio-cards-hovered": "#32404F",
};

export const COLORS_CSS_DECLARATION = `
:root {
${colorKeys.reduce((acc, colorKey) => {
  return `${acc}
    --color-${colorKey}: ${baseThemeColors[colorKey]};`;
}, "")}
}

html:has(.dark) {
  background-color: ${darkThemeColors["text-contrast"]};
}

.dark {
${colorKeys.reduce((acc, colorKey) => {
  return `${acc}
    --color-${colorKey}: ${darkThemeColors[colorKey]};`;
}, "")}
}
`;

export const COLORS = colorKeys.reduce((acc, colorKey) => {
  return {
    ...acc,
    [colorKey]: `var(--color-${colorKey})`,
  };
}, {}) as Record<ColorsName, string>;

// bg - text
export const CONTRAST_COLORS = {
  yellow: {
    bg: "#FDEA6E",
    text: "#272B30",
  },

  blue1: {
    bg: "#056AE3",
    text: "#F3F6F8",
  },

  blue2: {
    bg: "#C0DDFF",
    text: "#272B30",
  },

  blue3: {
    bg: "#1B43CF",
    text: "#F3F6F8",
  },

  orange: {
    bg: "#FFB905",
    text: "#F3F6F8",
  },

  gray: {
    bg: "#EEF1F4",
    text: "#272B30",
  },
};
