import { t } from "@lingui/macro";
import dayjs from "dayjs";
import { FC, useMemo, useState } from "react";
import { TrialBlock } from "src/prohiring/Dashboard/TrialBlock";
import { StatCard } from "src/uiKit/Cards/StatCard";
import { PageHeader } from "src/uiKit/Layouts/PageHeader";
import { PageTitle } from "src/uiKit/Typography/PageTitle";
import { COLORS } from "src/uiKit/assets/styles/colors";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { BarChart, PieChart, axisClasses } from "@mui/x-charts";

import { CompanyInfo } from "@common/company.types";
import { BillDoc, UsageAiRecord, UsageModal } from "@common/usage.types";
import { UserInfo } from "@common/user.types";

import { BillShortCard } from "./BillShortCard";
import { SetLimitModal } from "./SetLimitModal";
import UsageListTable from "./UsageListTable";
import {
  calculatePriceForUsage,
  modalPricePer1kTokensInput,
  modalPricePer1kTokensOutput,
} from "./data/price";

function getListOfDays(month: number, year: number): string[] {
  const daysInMonth = dayjs().year(year).month(month).daysInMonth();
  return Array.from({ length: daysInMonth }, (_, i) =>
    dayjs()
      .year(year)
      .month(month)
      .date(i + 1)
      .format("D MMM"),
  );
}

export const defaultUsageLimit = 40;
export const defaultMaxUsageLimit = 70;

interface UsageDashboard {
  usageRecords: UsageAiRecord[];
  bills: BillDoc[];
  users: Record<string, UserInfo | undefined>;
  companyInfo: CompanyInfo | null;
  updateLimits: (newLimit: number, newNotificationLimit: number) => void;
  isViewMode?: boolean;
  isLimitedByUsage: boolean;
}
export const UsageDashboard: FC<UsageDashboard> = ({
  usageRecords,
  bills,
  companyInfo,
  updateLimits,
  users,
  isViewMode,
  isLimitedByUsage,
}) => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const [pageMonth, setPageMonth] = useState<number>(currentMonth);
  const isAbleToSeeMonth = (month: number) => {
    if (month < 0) return false;

    const currentMonth = new Date().getMonth();
    return month <= currentMonth;
  };
  const [isShowLimitModal, setIsShowLimitModal] = useState(false);

  const sortedBills = bills.sort((a, b) => {
    const aDate = a.data.endTimestamp;
    const bDate = b.data.endTimestamp;
    return bDate - aDate;
  });

  const isNowMonth = pageMonth === currentMonth;
  const notificationEmailLimit = companyInfo?.notificationEmailLimit || defaultUsageLimit / 2;

  const limit = companyInfo?.usageLimit || defaultUsageLimit;
  const maxOrganizationLimit = companyInfo?.maxLimit || defaultMaxUsageLimit;
  const isNeedToIncreaseUsage = isLimitedByUsage && pageMonth === currentMonth;

  const monthDays = getListOfDays(pageMonth, currentYear);
  const pageMonthName = dayjs().month(pageMonth).format("MMM");
  const usageList = usageRecords;
  const firstMonthDayTimestamp = dayjs().month(pageMonth).startOf("month").valueOf();
  const lastMonthDayTimestamp = dayjs().month(pageMonth).endOf("month").valueOf();

  const thisMonthUsage = useMemo(
    () =>
      usageList.filter(
        (usageRecord) =>
          usageRecord.createdAt >= firstMonthDayTimestamp &&
          usageRecord.createdAt <= lastMonthDayTimestamp,
      ),
    [usageList, firstMonthDayTimestamp],
  );

  const dayPriceCvMap: Record<string, number> = {};
  const dayPriceVacancyMap: Record<string, number> = {};
  const dayPriceOtherMap: Record<string, number> = {};

  let totalVacancySpend = 0;
  let totalCvSpend = 0;
  let otherSpend = 0;

  const vacanciesList: string[] = [];
  const cvList: string[] = [];

  const thisMonthPrice = thisMonthUsage.reduce((acc, usage) => {
    const price = calculatePriceForUsage(usage);
    const dayName = dayjs(usage.createdAt).format("D MMM");

    const isCvUsage = !!usage.cvId;

    if (isCvUsage) {
      const cvPrice = dayPriceCvMap[dayName] || 0;
      dayPriceCvMap[dayName] = cvPrice + price;
      totalCvSpend += price;

      if (!cvList.includes(usage.cvId)) cvList.push(usage.cvId);
    }

    const isVacancyUsage = !!usage.vacancyId;
    if (isVacancyUsage) {
      const vacancyPrice = dayPriceVacancyMap[dayName] || 0;
      dayPriceVacancyMap[dayName] = vacancyPrice + price;
      totalVacancySpend += price;

      if (!vacanciesList.includes(usage.vacancyId)) vacanciesList.push(usage.vacancyId);
    }

    if (!isCvUsage && !isVacancyUsage) {
      const otherPrice = dayPriceOtherMap[dayName] || 0;
      dayPriceOtherMap[dayName] = otherPrice + price;
      otherSpend += price;
    }

    return acc + price;
  }, 0);

  const dailyUsage = monthDays.map((day) => {
    return {
      day: day,
      cv: dayPriceCvMap[day] || 0,
      vacancy: dayPriceVacancyMap[day] || 0,
      other: dayPriceOtherMap[day] || 0,
    };
  });

  const valueFormatter = (value: number | null) => `$${value?.toFixed(2)}`;

  const expectedBill = useMemo(() => {
    if (!isNowMonth) return 0;

    const totalDaysInMonth = dayjs().month(pageMonth).daysInMonth();
    const todayDate = dayjs().date(); // current day of the month (1-based index)
    const daysPassed = todayDate;
    const daysLeftInMonth = totalDaysInMonth - daysPassed;

    const averageDailySpend = daysPassed > 0 ? thisMonthPrice / daysPassed : 0;
    const estimatedRemainingSpend = averageDailySpend * daysLeftInMonth;

    return Math.round((thisMonthPrice + estimatedRemainingSpend) * 100) / 100;
  }, [isNowMonth, pageMonth, thisMonthPrice]);

  return (
    <Stack>
      <PageHeader>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          gap="20px"
          sx={{ width: "100%" }}
          flexWrap={"wrap"}
        >
          <Stack>
            <PageTitle>{t`Usage`}</PageTitle>
            <Typography
              variant="body2"
              sx={{
                color: COLORS["text-secondary"],
              }}
            >
              {t`Below you'll find a summary of usage for your organization.`}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap="20px"
            sx={{
              border: `1px solid ${COLORS["text-disabled"]}`,
              backgroundColor: COLORS["bg-card"],
              width: "280px",
              boxSizing: "border-box",
              borderRadius: "5px",
              padding: "3px 6px",
            }}
          >
            <IconButton
              onClick={() => setPageMonth(pageMonth - 1)}
              disabled={!isAbleToSeeMonth(pageMonth - 1)}
            >
              <NavigateBeforeIcon />
            </IconButton>

            <Typography color={COLORS["text-secondary"]} variant="body2" align="center">
              {pageMonthName} {currentYear}
            </Typography>

            <IconButton
              onClick={() => setPageMonth(pageMonth + 1)}
              disabled={!isAbleToSeeMonth(pageMonth + 1)}
            >
              <NavigateNextIcon />
            </IconButton>
          </Stack>
        </Stack>
      </PageHeader>

      <TrialBlock isViewMode={isViewMode} />

      <Stack
        gap={"70px"}
        direction={"row"}
        sx={{
          width: "100%",
          paddingTop: "30px",
          "@media (max-width: 1150px)": {
            flexDirection: "column-reverse",
          },
        }}
      >
        <Stack
          gap={"50px"}
          sx={{
            width: "calc(100% - 470px)",
            "@media (max-width: 1150px)": {
              width: "100%",
            },
          }}
        >
          <Stack gap="10px">
            <Typography variant="h3">{t`Monthly Spend`}</Typography>
            <Stack
              sx={{
                padding: "20px",
                border: `1px solid ${COLORS.border}`,
                borderRadius: "5px",
                position: "relative",
              }}
            >
              {thisMonthUsage.length === 0 && (
                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1,
                    top: "0px",
                    left: "0px",
                  }}
                >
                  <Typography
                    align="center"
                    sx={{
                      width: "100%",
                      color: COLORS["text-label"],
                    }}
                  >
                    {t`No data to display`}
                  </Typography>
                </Stack>
              )}

              <BarChart
                colors={["#6B4BD1", "#F66B6E", "#299940", "#F8961E", "#0DAEE5", "#ABC81D"]}
                dataset={dailyUsage}
                xAxis={[{ scaleType: "band", dataKey: "day", tickPlacement: "middle" }]}
                series={[
                  { dataKey: "cv", label: t`Candidates`, valueFormatter },
                  { dataKey: "vacancy", label: t`Vacancy`, valueFormatter },
                  { dataKey: "other", label: t`Other`, valueFormatter },
                ]}
                yAxis={[
                  {
                    label: "",
                  },
                ]}
                height={400}
                sx={{
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: "translate(-20px, 0)",
                  },
                }}
                slotProps={{
                  legend: {
                    position: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    itemMarkWidth: 13,
                    itemMarkHeight: 13,
                    itemGap: 15,
                  },
                }}
              />
            </Stack>
          </Stack>

          <Stack gap={"10px"}>
            <Typography variant="h3">{t`Spend by Categories`}</Typography>
            <Stack
              gap={"10px 10px"}
              direction={"row"}
              sx={{
                "@media (max-width: 800px)": {
                  flexDirection: "column",
                },
              }}
            >
              <StatCard
                value={"$" + totalVacancySpend.toFixed(2)}
                title={t`Vacancies`}
                subTitle={t`${vacanciesList.length} vacancies`}
              />

              <StatCard
                value={"$" + totalCvSpend.toFixed(2)}
                title={t`Candidates`}
                subTitle={t`${cvList.length} candidates`}
              />
              <StatCard value={"$" + otherSpend.toFixed(2)} title={t`Other`} subTitle={""} />
            </Stack>
          </Stack>

          <Stack gap={"10px"}>
            <Typography variant="h3">{t`Details`}</Typography>
            <UsageListTable usageList={thisMonthUsage} users={users} />
          </Stack>

          <Divider />
          <Stack gap={"20px"}>
            <Stack>
              <Typography variant="h3">{t`Price info`}</Typography>
              <Typography variant="caption">{t`Per 1000 tokens`}</Typography>
            </Stack>

            <Stack direction={"row"} gap="40px 220px" flexWrap={"wrap"}>
              <Stack gap={"10px"}>
                <Typography variant="h4">{t`Input tokens`}</Typography>
                <Stack>
                  {Object.keys(modalPricePer1kTokensInput).map((key) => {
                    const priceKey = key as UsageModal;
                    return (
                      <Stack key={key} gap={"10px"} direction={"row"}>
                        <Typography
                          sx={{
                            color: COLORS["text-label"],
                            width: "120px",
                          }}
                          variant="body2"
                        >
                          {priceKey}
                        </Typography>
                        <Typography variant="body2">
                          ${modalPricePer1kTokensInput[priceKey].toFixed(4)}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>

              <Stack gap={"10px"}>
                <Typography variant="h4">{t`Output tokens`}</Typography>
                <Stack>
                  {Object.keys(modalPricePer1kTokensOutput).map((key) => {
                    const priceKey = key as UsageModal;
                    return (
                      <Stack key={key} gap={"10px"} direction={"row"}>
                        <Typography
                          sx={{
                            color: COLORS["text-label"],
                            width: "120px",
                          }}
                          variant="body2"
                        >
                          {priceKey}
                        </Typography>
                        <Typography variant="body2">
                          ${modalPricePer1kTokensOutput[priceKey].toFixed(4)}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          gap={"70px"}
          sx={{
            width: "100%",
          }}
        >
          <Stack gap={"30px"}>
            <Typography variant="h3">{t`Monthly Bill`}</Typography>

            <Stack direction={"row"} gap={"35px"}>
              <Stack
                sx={{
                  position: "relative",
                  width: "180px",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "75px",
                    left: "0",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  {((thisMonthPrice / limit) * 100).toFixed(0)}%
                </Typography>
                <PieChart
                  colors={[isNeedToIncreaseUsage ? "#ee3344" : "#6B4BD1", "#c0c0c0"]}
                  width={180}
                  height={180}
                  sx={{}}
                  series={[
                    {
                      data: [
                        { value: thisMonthPrice, label: "Spend" },
                        { value: limit - thisMonthPrice, label: "Remaining" },
                      ],
                      innerRadius: 65,
                      outerRadius: 90,
                      paddingAngle: 1,
                      cornerRadius: 0,
                      startAngle: 0,
                      endAngle: 360,
                      cx: 85,
                      cy: 85,
                      valueFormatter: (value) => `$${value.value.toFixed(2)}`,
                    },
                  ]}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                />
              </Stack>
              <Stack
                gap={"10px"}
                sx={{
                  width: "150px",
                  paddingTop: "20px",
                }}
              >
                <Stack gap={"0px"}>
                  <Typography variant="h2">{"$" + thisMonthPrice.toFixed(2)}</Typography>
                  <Typography variant="caption">/ ${limit.toFixed(2)} limit</Typography>
                </Stack>
                <Button
                  disabled={isViewMode}
                  variant="contained"
                  color={isNeedToIncreaseUsage ? "error" : "primary"}
                  onClick={() => setIsShowLimitModal(true)}
                >
                  {t`Increase limit`}
                </Button>
              </Stack>
            </Stack>
          </Stack>

          {isNowMonth && (
            <Stack gap={"10px"}>
              <Stack>
                <Typography variant="h3">{t`Expected This Month Bill`}</Typography>
                <Typography variant="caption">{t`Based on current usage`}</Typography>
              </Stack>
              <Typography variant="h4">~${expectedBill}</Typography>
            </Stack>
          )}

          <Stack gap={"10px"}>
            <Typography variant="h3">{t`Bills`}</Typography>
            {sortedBills.length === 0 ? (
              <Stack>
                <Typography variant="body2" color={COLORS["text-secondary"]}>
                  {t`No data available`}
                </Typography>
              </Stack>
            ) : (
              <Stack gap="10px">
                {sortedBills.map((bill) => (
                  <BillShortCard
                    key={bill.id}
                    id={bill.id}
                    bill={bill.data}
                    isViewMode={isViewMode}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      {isShowLimitModal && (
        <SetLimitModal
          maxOrganizationLimit={maxOrganizationLimit}
          limit={limit}
          notificationLimit={notificationEmailLimit}
          onClose={() => setIsShowLimitModal(false)}
          onSave={({ limit, notificationLimit }) => {
            updateLimits(limit, notificationLimit);
            setIsShowLimitModal(false);
          }}
        />
      )}
    </Stack>
  );
};
