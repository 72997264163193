import { lazy } from "react";
import { Route } from "react-router-dom";

const SupportListPage = lazy(() => import("./SupportListPage/SupportListPage"));
const SupportEditorPage = lazy(() => import("./SupportEditorPage/SupportEditorPage"));

export const supportsListRouter = (
  <>
    <Route path="/support" element={<SupportListPage />} />
    <Route path="/support/:id" element={<SupportEditorPage />} />
  </>
);
