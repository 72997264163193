import { t } from "@lingui/macro";
import { FC, useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAccess } from "src/core/Access/useAccess/useAccess";
import db from "src/core/Backend/firestore";
import { companyName } from "src/core/Url/url";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";
import { DAY } from "src/core/Utils/time";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Button, Paper, Stack, Typography } from "@mui/material";

interface TrialBlockProps {
  isViewMode?: boolean;
}

const partnerDomains: string[] = ["hi-hh"];

export const TrialBlock: FC<TrialBlockProps> = ({ isViewMode }) => {
  const users = useUsers();
  const access = useAccess();
  const [isNeedToShowBanner, setIsNeedToShowBanner] = useState(false);
  const [companyInfo] = useDocumentData(db.documents.config.companyInfo);
  const trialEndedAt = companyInfo?.trialEndAt;
  const trialModeEndingConfirmed = companyInfo?.trialModeEndingConfirmed;
  const now = Date.now(); // + 15 * DAY;
  const isPartner = partnerDomains.includes(companyName || "");

  useEffect(() => {
    if (users.loading || users.isClientForHiring || !trialEndedAt) {
      return;
    }

    const isTrial = now < trialEndedAt || !trialModeEndingConfirmed;
    if (isTrial) {
      setIsNeedToShowBanner(true);
    }
  }, [users.loading, users.isClientForHiring, trialEndedAt]);

  if (
    isPartner ||
    !isNeedToShowBanner ||
    !trialEndedAt ||
    users.isClientForHiring ||
    !access.viewUsage
  ) {
    return null;
  }

  const isTrailEnded = now > trialEndedAt && !trialModeEndingConfirmed;
  if (isTrailEnded) {
    return (
      <Paper
        sx={{
          padding: "20px",
          gap: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack
          sx={{
            maxWidth: "260px",
          }}
        >
          <Typography variant="h3">{t`Your Free Trial Has Ended`}</Typography>
          <Typography
            sx={{
              color: COLORS["text-label"],
            }}
            variant="caption"
          >
            {t`Billing will commence at the start of the next month.`}
          </Typography>
        </Stack>
        <Stack gap={"0px"} sx={{}}>
          <Button disabled={isViewMode} variant="contained" href="/trial-next-steps">
            {t`Next Steps`}
          </Button>
        </Stack>
      </Paper>
    );
  }

  const daysBeforeTrialEnd = Math.ceil((trialEndedAt - now) / DAY);

  return (
    <Paper
      sx={{
        padding: "20px",
        gap: "20px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack>
        <Typography variant="h3">{t`Trial mode`}</Typography>
        <Typography
          sx={{
            color: COLORS["text-label"],
          }}
          variant="caption"
        >
          {t`Your trial ends in ${daysBeforeTrialEnd} days.`}
        </Typography>
      </Stack>
      <Stack gap={"0px"} sx={{}}>
        <Button disabled={isViewMode} variant="contained" href="/increase-trial-request">
          {t`Extend trial`}
        </Button>
      </Stack>
    </Paper>
  );
};
