import { fileFromBase64, fileToBase64 } from "src/core/Utils/base64";

const CONVERT_IMAGE_SERVICE_URL =
  "https://prohiring-images-507232057233.europe-central2.run.app/api/v1/image-converter-base64-webp";

export async function convertImage(file: File): Promise<File> {
  const imageBase64 = await fileToBase64(file);
  const requestData = { imageBase64 };

  const requestToConvert = await fetch(CONVERT_IMAGE_SERVICE_URL, {
    method: "POST",
    body: JSON.stringify(requestData),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseResult = await requestToConvert.json();

  const base64Url = `data:image/webp;base64,${responseResult.imageBase64}`;

  return await fileFromBase64(base64Url, file.name, "image/webp");
}
