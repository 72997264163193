import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import { COLORS } from "../assets/styles/colors";

export const FileList = styled.div({
  columnCount: 2,
  gap: "24px",
  columnRule: `1px solid ${COLORS.border}`,
  "@media (max-width: 800px)": {
    columnCount: 1,
  },
});

export const ListColumn = styled.div({
  boxSizing: "border-box",
  breakInside: "avoid",
});

export const ColumnHeader = styled.div({
  backgroundColor: COLORS["border"],
  borderRadius: "8px",
  padding: "12px 40px 12px 28px",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  boxSizing: "border-box",
});

export const NameOfCategory = styled(Typography)({
  color: COLORS["text-secondary"],
});

export const FilesCounterText = styled(Typography)({
  color: COLORS["text-label"],
});

export const FilesCounterContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",

  gap: "2px",
});

export const UploadedFiles = styled.div({
  boxSizing: "border-box",
  paddingTop: "16px",
  paddingLeft: "28px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

export const UploadedLabel = styled(Typography)({
  lineHeight: "1.25rem",
  color: COLORS["text-secondary"],
});

export const FileContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  paddingTop: "4px",
  boxSizing: "border-box",
  justifyContent: "center",
  alignItems: "center",
});

export const FileIcon = styled.div({
  color: COLORS["text-disabled"],
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  img: {
    width: "70px",
  },
});

export const FileName = styled.div({
  width: "100%",
  color: COLORS["text"],
});

export const ImgSmallPreview = styled.img({
  cursor: "pointer",
  ":hover": {
    opacity: 0.5,
  },
});

export const FileDeleteButton = styled.button({
  color: COLORS["text-secondary"],
  ":hover": {
    cursor: "pointer",
  },
  padding: 0,
  border: 0,
  backgroundColor: "transparent",
  marginRight: "28px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
