import * as Sentry from "@sentry/react";
import "normalize.css";
import React from "react";
import { createRoot } from "react-dom/client";
import "src/uiKit/assets/styles/globals.css";

import { StyledEngineProvider } from "@mui/material/styles";

import App from "./App";
import { isDevEnvironment } from "./core/Url/url";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

if (!isDevEnvironment) {
  Sentry.init({
    dsn: "https://a0734d9be422ab76e8faf84137e956b7@o4506187426103296.ingest.sentry.io/4506187428265984",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ["ResizeObserver loop completed with undelivered notifications."],
  });
}

const container = document.getElementById("root");
const root = createRoot(container!); // eslint-disable-line
root.render(
  <StyledEngineProvider injectFirst>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </StyledEngineProvider>,
);

serviceWorkerRegistration.register();
