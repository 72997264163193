import { t } from "@lingui/macro";
import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import db from "src/core/Backend/firestore";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";
import { Tabs } from "src/uiKit/Tabs/Tabs";

import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";

import { getFullVacancyTitle } from "../Vacancy/lib/getFullVacancyTitle";
import { AccessSettings } from "./AccessSettings";
import { AiSearchSettings } from "./AiSearchSettings";
import { SourceSettings } from "./SourceSettings";
import { TagsSettings } from "./TagsSettings";
import { VacancySettingsTab, useVacancySettingsUI } from "./hooks/useVacancySettingsUI";

export const VacancySettings = () => {
  const vacancySettingsState = useVacancySettingsUI();

  const users = useUsers();
  const isClientForHiring = users.isClientForHiring;
  const [vacancy] = useDocumentData(
    vacancySettingsState.vacancyId
      ? doc(db.collections.vacancies, vacancySettingsState.vacancyId)
      : null,
  );
  const vacancyTitle = vacancy ? getFullVacancyTitle(vacancy) : "";

  if (
    isClientForHiring ||
    !vacancySettingsState.vacancyId ||
    vacancySettingsState.openSettingsTabIndex === null
  ) {
    return <></>;
  }

  return (
    <Drawer anchor="right" open={true} onClose={() => vacancySettingsState.closeSettings()}>
      <Stack
        sx={{
          padding: "20px 30px",
          width: "600px",
          boxSizing: "border-box",
          "@media (max-width: 600px)": {
            padding: "20px 8px",
            width: "100vw",
          },
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            paddingBottom: "20px",
          }}
        >
          <Stack>
            <Typography variant="h3">{t`Vacancy Settings`}</Typography>
            <Typography variant="caption">{vacancyTitle}</Typography>
          </Stack>

          <IconButton onClick={() => vacancySettingsState.closeSettings()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            paddingBottom: "20px",
          }}
        >
          <Tabs
            tabs={vacancySettingsState.tabs}
            activeIndex={vacancySettingsState.openSettingsTabIndex}
            onChange={(label: string, id: string) =>
              vacancySettingsState.openSettings(
                id as VacancySettingsTab,
                vacancySettingsState.vacancyId,
              )
            }
          />
        </Stack>

        <Stack
          sx={{
            width: "100%",
          }}
        >
          {vacancySettingsState.openSettingsTabId === "access-settings" && (
            <AccessSettings vacancyId={vacancySettingsState.vacancyId} />
          )}
          {vacancySettingsState.openSettingsTabId === "ai-search" && (
            <AiSearchSettings vacancyId={vacancySettingsState.vacancyId} />
          )}
          {vacancySettingsState.openSettingsTabId === "source" && (
            <SourceSettings vacancyId={vacancySettingsState.vacancyId} />
          )}

          {vacancySettingsState.openSettingsTabId === "tags" && (
            <TagsSettings vacancyId={vacancySettingsState.vacancyId} />
          )}
        </Stack>
      </Stack>
    </Drawer>
  );
};
