import { t } from "@lingui/macro";
import { doc, query, setDoc, where } from "firebase/firestore";
import { useMemo, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { appType } from "src/core/AppConfig/appConfig";
import { useAuth } from "src/core/Auth/useAuth";
import db from "src/core/Backend/firestore";
import { useMessages } from "src/core/Messages/useMessages/useMessages";
import { COLORS } from "src/uiKit/assets/styles/colors";

import CloseIcon from "@mui/icons-material/Close";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Badge, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import { SupportRequest } from "@common/support.types";

import { Comments } from "../../Comments/Comments";

export const SupportButton = (): JSX.Element => {
  const auth = useAuth();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const messages = useMessages();

  const [documentList] = useCollection(
    auth.uid ? query(db.collections.support, where("createdBy", "==", auth.uid)) : null,
  );

  const listOfSupports = useMemo(
    () =>
      documentList?.docs.map((document) => {
        return { data: document.data(), id: document.id };
      }) || [],
    [documentList],
  );

  const activeRequest = listOfSupports.find((support) => support.data.status === "Open");

  const newRoomMessages = messages.myMessages.filter(
    (message) =>
      message.data.roomType === "support" &&
      message.data.roomId === activeRequest?.id &&
      message.data.isSupportTeam &&
      !message.data.readByUserIds?.includes(auth.uid),
  );

  const newMessagesCount = newRoomMessages.length;
  const isActive = activeRequest;

  const openModal = async () => {
    if (isOpenModal) {
      setIsOpenModal(false);
      return;
    }

    setIsOpenModal(true);
    if (activeRequest) return;

    const emptyData: SupportRequest = {
      appType: appType,
      status: "Open",
      createdAt: Date.now(),
      createdBy: auth.uid,
    };

    const uid = doc(db.collections.support);
    await setDoc(uid, emptyData);
  };

  if (!auth?.uid) return <></>;

  return (
    <>
      {activeRequest && isOpenModal && (
        <Stack
          sx={{
            position: "fixed",
            bottom: "87px",
            right: "20px",
            zIndex: 1000,
            boxShadow:
              "0px 0px 10px rgba(0, 0, 0, 0.1), 4px 4px 20px rgba(0, 0, 0, 0.1), 0px 0px 0 1px rgba(0, 0, 0, 0.1)",
            backgroundColor: COLORS["bg-card"],
            borderRadius: "18px",
            padding: "20px 20px 40px 30px",
            gap: "20px",
            width: "500px",
            maxHeight: "calc(100vh - 200px)",
            overflow: "auto",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography variant="h4">{t`Support`}</Typography>
            <IconButton onClick={() => setIsOpenModal(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              backgroundColor: COLORS["text-contrast"],
              borderRadius: "12px",
              gap: "20px",
            }}
          >
            <Comments
              richText
              canComment
              appType={appType}
              roomType="support"
              roomId={activeRequest.id}
              canAttachFiles={true}
              emptyMessage={t`No comments yet`}
              placeholder={t`Add message`}
            />
          </Stack>
        </Stack>
      )}

      <Stack
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
          "@media (max-width: 600px)": {
            display: "none",
          },
        }}
      >
        <Badge badgeContent={newMessagesCount} color="error">
          <Tooltip title="Support team" placement="right">
            <IconButton
              size="large"
              sx={{
                border: "3px solid",
                borderColor: isActive ? COLORS["primary"] : COLORS.border,
                backgroundColor: isActive ? COLORS["bg-card"] : COLORS["bg"],
                ":hover": {
                  backgroundColor: isActive ? COLORS["bg-card"] : "transparent",
                },
              }}
              onClick={openModal}
            >
              <SupportAgentIcon />
            </IconButton>
          </Tooltip>
        </Badge>
      </Stack>
    </>
  );
};
