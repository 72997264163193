import { SystemRole, SystemRoleAccess } from "@common/systemRole.types";

const zeroAccessSystemRole: SystemRoleAccess = {
  viewGlobalHiringPipeline: false,
  setGlobalHiringPipeline: false,
  setVacancyHiringPipeline: false,
  viewVacancies: false,
  setVacancy: false,
  viewUsage: false,
  setGlobalSystemRoles: false,
  viewUsers: false,
  editUsersInfo: false,
  editUsersAccess: false,
  inviteUser: false,
  viewCvBase: false,
  viewCandidateMoney: false,
  setCompanyInfo: false,
  setDepartment: false,
  approveOrDeclineShowcase: false,
  addShowcase: false,
  viewPortfolio: false,
  sharePortfolioLink: false,
  viewOwnShowcases: false,
  viewOtherShowcases: false,
  inviteClient: false,
  viewClients: false,
};

const fullAccessSystemRole: SystemRoleAccess = Object.keys(zeroAccessSystemRole).reduce(
  (acc, key) => ({ ...acc, [key]: true }),
  {} as SystemRoleAccess,
);

export const emptyAccessSystemRole: SystemRole = {
  id: "",
  isLocked: true,
  title: "",
  description: "",
  createdAt: 0,
  createdBy: "",
  updatedAt: 0,
  updatedBy: "",
  ...zeroAccessSystemRole,
};

export const superAdminAccess: SystemRole = {
  isLocked: true,
  id: "",
  title: "",
  description: "",
  createdAt: 0,
  createdBy: "",
  updatedAt: 0,
  updatedBy: "",
  ...fullAccessSystemRole,
};
