import { FC } from "react";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Card, Typography } from "@mui/material";

interface StatCardProps {
  title: string;
  value: string | number;
  subTitle: string;
}

export const StatCard: FC<StatCardProps> = ({ title, value, subTitle }) => {
  return (
    <Card
      sx={{
        padding: "30px 30px",
        boxSizing: "border-box",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        backgroundColor: COLORS["text-contrast"],
        //boxShadow: "0px 2px 42px rgba(0, 0, 0, 0.01)",
        //borderRadius: "15px",
        "@media (max-width: 800px)": {
          padding: "20px 20px",
        },
      }}
    >
      <Typography variant="h1">
        <b>{value}</b>
      </Typography>
      <Typography
        sx={{
          color: COLORS["text-label"],
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          color: COLORS["text-label"],
        }}
      >
        {subTitle}
      </Typography>
    </Card>
  );
};
