import styled from "@emotion/styled";

import { COLORS } from "../assets/styles/colors";

export const ModalBox = styled.div({
  position: "absolute",
  top: "50%",
  left: "50%",
  background: COLORS["text-contrast"],
  transform: "translate(-50%, -50%)",
  width: "480px",
  minHeight: "202px",
  borderRadius: "5px",
});

export const ModalFullBox = styled.div({
  position: "absolute",
  top: "50%",
  left: "50%",
  background: COLORS["text-contrast"],
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: "95%",
  padding: "40px",
  height: "95%",
  overflow: "auto",
  boxSizing: "border-box",
  "@media (max-width: 1000px)": {
    width: "98%",
    height: "98%",
    padding: "5px",
  },
});
