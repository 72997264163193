import { lazy } from "react";
import { Route } from "react-router-dom";

const UsagePage = lazy(() => import("./UsagePage"));
const IncreaseLimitRequest = lazy(() => import("./IncreaseLimitRequest"));
const IncreaseTrialRequest = lazy(() => import("./IncreaseTrialRequest"));
const TrialFinishedPage = lazy(() => import("./TrialFinishedPage"));
const BillingPage = lazy(() => import("./Billing/BillingPage"));

export const usageRouter = (
  <>
    <Route path="/usage" element={<UsagePage />} />
    <Route path="/increase-limit-request" element={<IncreaseLimitRequest />} />
    <Route path="/increase-trial-request" element={<IncreaseTrialRequest />} />
    <Route path="/trial-next-steps" element={<TrialFinishedPage />} />
    <Route path="/billing" element={<BillingPage />} />
  </>
);
export default usageRouter;
