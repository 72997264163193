import { lazy } from "react";
import { Route } from "react-router-dom";

const DepartmentsListPage = lazy(() => import("./CompanyStructurePage"));
const UsersDepartments = lazy(() => import("./UsersDepartments"));
const EditDepartment = lazy(() => import("./EditDepartment"));

export const companyStructureRouter = (
  <>
    <Route path="company-structure" element={<DepartmentsListPage />} />
    <Route path="company-structure/:departmentId" element={<EditDepartment />} />
    <Route path="company-structure/users/:departmentId" element={<UsersDepartments />} />
  </>
);
