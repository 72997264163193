import { t } from "@lingui/macro";
import dayjs from "dayjs";
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useMemo } from "react";
import {
  TABLE_COLUMN_STYLE,
  defaultTableStyle,
  useTableLocalizations,
} from "src/core/Table/useTable";
import { companyName } from "src/core/Url/url";
import { downloadStringAsFile } from "src/core/Utils/download";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button, Link, Stack, Typography } from "@mui/material";

import { UsageAiRecord } from "@common/usage.types";
import { UserInfo } from "@common/user.types";

import { calculatePriceForUsage } from "./data/price";

interface UsageListTableProps {
  usageList: UsageAiRecord[];
  users: Record<string, UserInfo | undefined>;
}

interface UsageRowInfo extends UsageAiRecord {
  userName: string;
  userEmail: string;
  price: number;
}

const UsageListTable = ({ usageList, users }: UsageListTableProps): JSX.Element => {
  const localization = useTableLocalizations();

  const columns = useMemo<MRT_ColumnDef<UsageRowInfo>[]>(() => {
    const baseColumns: MRT_ColumnDef<UsageRowInfo>[] = [
      {
        accessorKey: "userName",
        header: t`User`,
        ...TABLE_COLUMN_STYLE.default,
        Cell: ({ row }) => {
          const userName = row.original.userName;
          const email = row.original.userEmail;
          return (
            <Stack>
              <Typography variant="body2">{userName}</Typography>
              <Typography variant="caption" color="text.secondary">
                {email}
              </Typography>
            </Stack>
          );
        },
      },
      {
        accessorKey: "price",
        header: t`Price`,
        ...TABLE_COLUMN_STYLE.default,
        size: 100,
        Cell: ({ row }) => {
          const price = row.original.price;
          return <Typography variant="body2">${price.toFixed(5)}</Typography>;
        },
      },
      {
        accessorKey: "createdAt",
        header: t`Date`,
        ...TABLE_COLUMN_STYLE.default,
        size: 140,
        Cell: ({ row }) => {
          const createdAt = row.original.createdAt;
          const date = dayjs(createdAt).format("DD.MM.YYYY HH:mm");
          const relativeDate = dayjs(createdAt).fromNow();
          return (
            <Stack>
              <Typography variant="body2">{relativeDate}</Typography>
              <Typography variant="caption" color="text.secondary">
                {date}
              </Typography>
            </Stack>
          );
        },
      },
      {
        accessorKey: "label",
        header: t`Description`,
        ...TABLE_COLUMN_STYLE.default,
      },
      {
        accessorKey: "vacancyId",
        ...TABLE_COLUMN_STYLE.default,
        size: 70,
        header: t`Vacancy ID`,
        Cell: ({ row }) => {
          const vacancyId = row.original.vacancyId;
          if (!vacancyId) return <Typography variant="body2">-</Typography>;
          return <Link href={`/vacancy-view/${vacancyId}`}>{vacancyId}</Link>;
        },
      },
      {
        accessorKey: "cvId",
        size: 70,
        ...TABLE_COLUMN_STYLE.default,
        header: t`CV`,
        Cell: ({ row }) => {
          const cvId = row.original.cvId;
          if (!cvId) return <Typography variant="body2">-</Typography>;
          return <Link href={`/cv-editor/${cvId}`}>{cvId}</Link>;
        },
      },
      {
        accessorKey: "inputTokensCount",
        header: t`Tokens (Input)`,
        ...TABLE_COLUMN_STYLE.default,
      },

      {
        accessorKey: "outputTokensCount",
        header: t`Tokens (Output)`,
        ...TABLE_COLUMN_STYLE.default,
      },

      {
        accessorKey: "model",
        header: t`AI Model`,
        ...TABLE_COLUMN_STYLE.default,
      },
    ];

    return baseColumns;
  }, [localization.lang]);

  const data = useMemo<UsageRowInfo[]>(() => {
    return usageList
      .map((usage) => {
        const price = calculatePriceForUsage(usage);
        return {
          ...usage,
          userName: users[usage.userId]?.fullName || "",
          userEmail: users[usage.userId]?.email || t`Unknown user`,
          price: price,
        };
      })
      .sort((a, b) => b.createdAt - a.createdAt);
  }, [usageList, users]);

  const exportDataToExcel = () => {
    const cvsRows =
      [
        `price`,
        `model`,
        `inputTokensCount`,
        `outputTokensCount`,
        `label`,
        `createdAt`,
        `userName`,
        `userEmail`,
        `vacancyId`,
        `cvId`,
      ].join(",") +
      "\n" +
      data
        .map((row) => {
          return [
            row.price,
            row.model,
            row.inputTokensCount,
            row.outputTokensCount,
            row.label,
            row.createdAt,
            row.userName,
            row.userEmail,
            row.vacancyId,
            row.cvId,
          ].join(",");
        })
        .join("\n");

    const today = new Date();
    const todayString = today.toISOString().split("T")[0];
    const fileTitle = `usage ${todayString} (${companyName}).csv`;
    downloadStringAsFile(cvsRows, fileTitle);
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    localization: localization.tableLocalization,
    ...defaultTableStyle,
  });

  return (
    <Stack>
      <MaterialReactTable table={table} />
      <Stack
        sx={{
          padding: "10px 0",
          width: "max-content",
        }}
      >
        <Button variant="outlined" startIcon={<FileDownloadIcon />} onClick={exportDataToExcel}>
          {t`Export to CSV`}
        </Button>
      </Stack>
    </Stack>
  );
};

export default UsageListTable;
