import { Context, createContext } from "react";

import { ActionInfo } from "@common/vacancy.types";

export interface HiringFeedContext {
  loading: boolean;
  actionInfo: ActionInfo[];
  showMore: () => void;
  isFullLoaded: boolean;
}

export const hiringFeedContext: Context<HiringFeedContext> = createContext<HiringFeedContext>({
  loading: false,
  actionInfo: [],
  showMore: () => {},
  isFullLoaded: false,
});
