import { Context, createContext } from "react";

import { Message } from "@common/message.types";

import { MessageDoc } from "./types";

export interface MessagesContext {
  messages: MessageDoc[];
  myMessages: MessageDoc[];
  markMessageAsRead: (message: MessageDoc) => void;
  deleteMessage: (messageId: string) => void;
  createMessage: (message: Message) => void;
  updateMessage: (messageId: string, message: Message) => void;
  isNewMessage: (messageId: MessageDoc) => boolean;
}

export const messagesContext: Context<MessagesContext> = createContext<MessagesContext>({
  messages: [],
  myMessages: [],
  markMessageAsRead: () => {
    throw new Error("MessagesContext not implemented");
  },
  deleteMessage: () => {
    throw new Error("MessagesContext not implemented");
  },
  createMessage: () => {
    throw new Error("MessagesContext not implemented");
  },
  updateMessage: () => {
    throw new Error("MessagesContext not implemented");
  },
  isNewMessage: () => {
    throw new Error("MessagesContext not implemented");
  },
});
