import React, { useEffect } from "react";

import { Tabs as MaterialUITabs, tabsClasses } from "@mui/material";

import { BoxContainer, BoxWrapper, WrappedTab } from "./style";

export interface TabInfo {
  label: string;
  badgeCount?: string;
  transparent?: boolean;
  icon?: JSX.Element;
  id?: string;
}
interface TabsProps {
  tabs: TabInfo[];
  onChange: (label: string, id: string) => void;
  activeIndex?: number;
  showScrollButtons?: boolean;
  border?: boolean;
}

export const Tabs = ({
  tabs,
  onChange,
  activeIndex,
  showScrollButtons,
  border,
}: TabsProps): JSX.Element => {
  const [tab, setTab] = React.useState(activeIndex || 0);

  useEffect(() => {
    setTab(activeIndex || 0);
  }, [activeIndex]);

  const onTabChange = (e: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
    const tabId = tabs[newTab]?.id || "";
    onChange(tabs[newTab].label, tabId);
  };

  return (
    <BoxContainer>
      <BoxWrapper borders={border !== false ? "true" : ""}>
        <MaterialUITabs
          value={tab}
          onChange={onTabChange}
          variant="scrollable"
          sx={{
            ...(showScrollButtons
              ? {
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }
              : {}),
          }}
        >
          {tabs.map((tabData, index) => (
            <WrappedTab
              key={index}
              label={tabData.label}
              badge={tabData.badgeCount || ""}
              icon={tabData.icon}
              iconPosition="start"
              opacity={tabData.transparent ? "0.35" : "1"}
            />
          ))}
        </MaterialUITabs>
      </BoxWrapper>
    </BoxContainer>
  );
};
