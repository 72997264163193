import { documentId, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import db from "src/core/Backend/firestore";
import { chunkArray } from "src/core/Utils/array";

import { CVPreview } from "@common/cv.types";

import { CvPreviewContext, CvPreviewElement } from "./useCvPreviewContext";

export function useProviderCvPreview(): CvPreviewContext {
  const [cvData, setCvData] = useState<Record<string, CVPreview>>({});

  const getCvData = async (cvIds: string[]): Promise<CvPreviewElement[]> => {
    if (cvIds.length === 0) {
      return [];
    }
    const newCvIds = cvIds.filter((id) => !cvData[id]);

    const cvIdChunks = chunkArray(newCvIds, 10);

    const allNewCvDocs = await Promise.all(
      cvIdChunks.map(async (idChunk) => {
        const involvedCvQuery = query(db.collections.cvPreview, where(documentId(), "in", idChunk));
        return await getDocs(involvedCvQuery);
      }),
    );

    const resultMap: Record<string, CVPreview> = {};

    allNewCvDocs.forEach((cvDocs) => {
      cvDocs.docs.forEach((cvDoc) => {
        const cvData = cvDoc.data();
        resultMap[cvDoc.id] = cvData;
      });
    });

    setCvData((prev) => ({ ...prev, ...resultMap }));

    return cvIds.map((id) => ({ id, data: resultMap[id] || cvData[id] }));
  };

  return {
    cvData,
    getCvData,
  };
}
