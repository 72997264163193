import { FC, useEffect, useState } from "react";

interface AutoSaveFieldProps {
  value: string;
  onSave: (value: string) => Promise<void>;
  setIsSaving: (isSaving: boolean) => void;
  fieldComponent: ({
    value,
    onChange,
    onBlur,
    onFocus,
  }: {
    value: string;
    onChange: (newValue: string) => void;
    onFocus: () => void;
    onBlur: () => void;
  }) => JSX.Element;
}

export const AutoSaveField: FC<AutoSaveFieldProps> = ({
  value,
  onSave,
  setIsSaving,
  fieldComponent,
}) => {
  const [internalValue, setInternalValue] = useState(value);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (isFocus) return;
    setInternalValue(value);
  }, [value, isFocus]);

  useEffect(() => {
    if (!isFocus) return;

    const timeout = setTimeout(() => {
      setIsSaving(true);
      onSave(internalValue).then(() => {
        setIsSaving(false);
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [internalValue]);

  return fieldComponent({
    value: internalValue,
    onChange: (newValue) => setInternalValue(newValue),
    onFocus: () => setIsFocus(true),
    onBlur: () => {
      setIsFocus(false);
      if (internalValue !== value) {
        setIsSaving(true);
        onSave(internalValue).then(() => {
          setIsSaving(false);
        });
      }
    },
  });
};
