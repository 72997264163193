import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import { Components, ComponentsOverrides } from "@mui/material";

import { COLORS } from "../assets/styles/colors";

const style: ComponentsOverrides["MuiOutlinedInput"] = {
  input: {
    display: "block!important",
    backgroundColor: COLORS["text-contrast"],
    padding: "10px 12px",
    "&::placeholder": {
      opacity: 1,
      color: COLORS["text-secondary"],
    },
    ":disabled": {
      backgroundColor: COLORS["bg"],
      borderColor: COLORS.border,
      color: COLORS["text-secondary"],
    },
  },
  multiline: {
    padding: 0,
  },
  root: {
    fontFamily: "var(--font-family)",
    "::-webkit-input-placeholder": {
      color: "red",
      overflow: "visible",
      opacity: "1!important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${COLORS.border}`,
      "&::placeholder": {
        overflow: "visible",
        textOverflow: "ellipsis !important",
        color: "blue",
      },
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid",
      borderColor: COLORS["primary"],
    },
    "&.Mui-hover .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS["text-secondary"],
    },
    "&.Mui-error": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: COLORS["error"],
      },
    },
  },
};

export const customOutlinedInput: GetTypeOfProperty<Components, "MuiOutlinedInput"> = {
  styleOverrides: style,
  defaultProps: {
    notched: false,
  },
};
