import { t } from "@lingui/macro";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMessages } from "src/core/Messages/useMessages/useMessages";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";
import { uniq } from "src/core/Utils/array";
import { useCvPreview } from "src/prohiring/Candidates/usePreviewCv/useCvPreview";
import { useClientCompanies } from "src/prohiring/ClientCompany/hooks/useClientCompanies/useClientCompanies";
import { useVacancies } from "src/prohiring/Vacancy/hooks/useVacancies/useVacancies";
import ColorTextAvatar from "src/uiKit/Avatar/ColorTextAvatar";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Box, Button, Stack, Typography } from "@mui/material";

import { MessageRoomType } from "@common/message.types";

import { MessageContent } from "./MessageContent";
import { FeedComment } from "./style";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface MessagesFeedProps {
  onClose?: () => void;
}
export function MessagesFeed({ onClose }: MessagesFeedProps): JSX.Element {
  const { myMessages, markMessageAsRead, isNewMessage } = useMessages();
  const { users } = useUsers();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(7);
  const limitedMessages = myMessages.filter((_, index) => index < limit);
  const isLimited = myMessages.length > limitedMessages.length;
  const cvLoader = useCvPreview();

  useEffect(() => {
    const cvIds = uniq(
      myMessages
        .filter((message) => message.data.cvId)
        .map((message) => message.data.cvId) as string[],
    );

    cvLoader.getCvData(cvIds);

    if (myMessages.length) {
      myMessages.forEach((message) => markMessageAsRead(message));
    }
  }, [myMessages]);

  const [vacancyList] = useVacancies();
  const { clientCompaniesList } = useClientCompanies();

  const getCvName = (cvId: string) => {
    const cvData = cvLoader.cvData[cvId];
    if (!cvData) {
      return "";
    }

    return cvData.name || cvData.email || "";
  };

  const getVacancyById = (vacancyId: string) =>
    vacancyList.find((vacancy) => vacancy.id === vacancyId)?.data;

  const getClientById = (clientId: string) =>
    clientCompaniesList.find((client) => client.id === clientId)?.data;

  return (
    <Box>
      {myMessages.length === 0 && (
        <Typography variant="body2" color={COLORS["text-secondary"]}>
          {t`No comments yet`}
        </Typography>
      )}
      <Stack gap="20px">
        {limitedMessages.map((message, index) => {
          const authorUserInfo = users[message.data.authorUserId];
          const authorName = message.data.isSupportTeam
            ? "Support team"
            : authorUserInfo?.fullName || authorUserInfo?.email || "";
          const isDraft = message.data.isDraft;
          const isNew = isNewMessage(message);

          const createdTime = message.data.createdAt;
          const createdTimeHuman = createdTime ? dayjs(createdTime).fromNow() : "";

          const clickHandler = () => {
            const clickHandlerMap: Record<MessageRoomType, () => void> = {
              showcase: () => {
                navigate(`/showcase-editor/${message.data.roomId}?showComment=` + message.id);
                onClose?.();
              },
              vacancy: () => {
                navigate(`/vacancy-editor/${message.data.roomId.split("_")[0]}`);
                onClose?.();
              },
              candidate: () => {
                navigate(`/cv-editor/${message.data.roomId}?candidateViewTab=documents`);
                onClose?.();
              },
              candidate_permission: () => {
                navigate(`/cv-editor/${message.data.roomId}?candidateViewTab=permissions`);
                onClose?.();
              },
              support: () => {
                navigate(`/support/${message.data.roomId}`);
                onClose?.();
              },
              candidate_on_vacancy: () => {
                // Legacy candidate view
                const vacancyId = message.data.roomId.split("_")[0];
                const candidateId = message.data.roomId.split("_")[1];
                const url = `/vacancy-cv/${vacancyId}?cvId=${candidateId}&viewType=notes`;
                navigate(url);
                onClose?.();
              },
              vacancy_candidate_comment: () => {
                const vacancyId = message.data.roomId.split("_")[0];
                const candidateId = message.data.roomId.split("_")[1];
                const candidateViewTab = message.data.roomId.split("_")[2];
                const url = `/vacancy-cv-board/${vacancyId}?candidateFullViewCvId=${candidateId}&candidateViewTab=${candidateViewTab}`;
                navigate(url);
                onClose?.();
              },
            };

            clickHandlerMap[message.data.roomType]?.();
          };
          return (
            <Stack
              key={index}
              gap="0"
              sx={{
                backgroundColor: isNew ? `rgba(0, 0, 0, 0.05)` : `transparent`,
                borderRadius: "8px",
              }}
              onMouseOver={() => markMessageAsRead(message)}
            >
              <FeedComment onClick={clickHandler}>
                <Stack
                  direction={"row"}
                  gap={"10px"}
                  sx={{
                    padding: "0px 0 5px 0",
                    alignItems: "center",
                  }}
                >
                  <ColorTextAvatar
                    src={authorUserInfo?.photoURL || ""}
                    name={authorName}
                    sx={{
                      width: "30px",
                      height: "30px",
                    }}
                  />

                  <Typography variant="body1" color={COLORS["text-label"]}>
                    {authorName}
                  </Typography>

                  <Typography
                    variant="caption"
                    sx={{
                      color: COLORS["text-secondary"],
                      paddingLeft: "10px",
                      fontStyle: "italic",
                    }}
                  >
                    {createdTimeHuman} {isDraft ? "(Draft)" : ""}
                  </Typography>
                </Stack>
                <Stack direction={"row"} gap={"10px"}>
                  {message.data.vacancyId && (
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: 500,
                        color: COLORS["text-secondary"],
                      }}
                    >
                      {getVacancyById(message.data.vacancyId)?.jobTitle}
                      {" - "}
                      {getVacancyById(message.data.vacancyId)?.attribute} (
                      {
                        getClientById(getVacancyById(message.data.vacancyId)?.clientCompanyId || "")
                          ?.name
                      }
                      )
                    </Typography>
                  )}

                  {message.data.vacancyId && message.data.cvId && (
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: 500,
                        color: COLORS["text-secondary"],
                      }}
                    >
                      ●
                    </Typography>
                  )}

                  {message.data.cvId && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: COLORS["text-secondary"],
                      }}
                    >
                      {getCvName(message.data.cvId)}
                    </Typography>
                  )}
                </Stack>
                <Stack>
                  <MessageContent
                    isRichText={!!message.data.richTextContent}
                    message={message.data.richTextContent || message.data.content}
                    maxSymbols={300}
                  />
                </Stack>
              </FeedComment>
            </Stack>
          );
        })}
      </Stack>
      {isLimited && (
        <Stack justifyContent={"center"} sx={{ padding: "10px" }}>
          <Button variant="text" sx={{ cursor: "pointer" }} onClick={() => setLimit(limit + 5)}>
            {t`Show more`}
          </Button>
        </Stack>
      )}
    </Box>
  );
}

export default MessagesFeed;
