import { CategoryInfo } from "@common/vacancy.types";

export const categoriesColorsOptions = [
  "#4BCE97",
  "#E774BB",
  "#FEA362",
  "#F87168",
  "#ff9900",
  "#9F8FEF",
  "#579DFF",
  "#94C748",
  "#F9C74F",
  "#F58A8A",
  "#B9B9B9",
  "#000000",
];

export const defaultCategories: CategoryInfo[] = [
  {
    color: categoriesColorsOptions[0],
    value: "",
    id: "1",
  },
  {
    color: categoriesColorsOptions[1],
    value: "",
    id: "2",
  },
  {
    color: categoriesColorsOptions[2],
    value: "",
    id: "3",
  },
];
