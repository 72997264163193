export const clearPhoneNumber = (phoneNumber: string): string => {
  const completeNumber = phoneNumber
    .trim()
    .replace(/[\s-]/g, "")
    // remove non-digit characters except for + on the left
    .replace(/[^+\d]/g, "")
    .replace(/(\d)\D+(\d)/g, "$1$2")
    .replace(/[()]/g, "");
  return completeNumber;
};
