/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable prefer-rest-params */
import { useEffect } from "react";
import { appType } from "src/core/AppConfig/appConfig";
import { isDevEnvironment } from "src/core/Url/url";

export const useHotjar = () => {
  useEffect(() => {
    if (isDevEnvironment) {
      return;
    }

    (function (h: any, o: any, t: any, j: any) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };

      if (appType === "hiring") {
        h._hjSettings = { hjid: 3726749, hjsv: 6 };
      } else if (appType === "profolio") {
        h._hjSettings = { hjid: 3726878, hjsv: 6 };
      }
      const a = o.getElementsByTagName("head")[0];
      const r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }, []);
};
