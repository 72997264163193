import { useNavigate } from "react-router-dom";

import { ChangePasswordModal } from "../Auth/ChangePassword/ChangePasswordModal";
import { LanguageSettingModal } from "../Language/LanguageSettingModal";
import { NotificationsSettingsModal } from "../Notifications/NotificationsSettingsModal";
import { useQuery } from "../Url/useQuery";
import { MyProfileModal } from "../Users/MyProfile/MyProfileModal";
import { GLOBAL_MODALS } from "./data";

export const GlobalModals = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const isModalOpen = (nameOfModal: string) => query.get(nameOfModal) === "true";
  const closeModal = (nameOfModal: string) => {
    const newQuery = new URLSearchParams(window.location.search);
    newQuery.delete(nameOfModal);
    navigate({ search: newQuery.toString() });
  };

  if (isModalOpen(GLOBAL_MODALS.LANGUAGE)) {
    return <LanguageSettingModal onClose={() => closeModal(GLOBAL_MODALS.LANGUAGE)} />;
  }

  if (isModalOpen(GLOBAL_MODALS.NOTIFICATIONS)) {
    return <NotificationsSettingsModal onClose={() => closeModal(GLOBAL_MODALS.NOTIFICATIONS)} />;
  }

  if (isModalOpen(GLOBAL_MODALS.CHANGE_PASSWORD)) {
    return <ChangePasswordModal onClose={() => closeModal(GLOBAL_MODALS.CHANGE_PASSWORD)} />;
  }

  if (isModalOpen(GLOBAL_MODALS.MY_PROFILE)) {
    return <MyProfileModal onClose={() => closeModal(GLOBAL_MODALS.MY_PROFILE)} />;
  }

  return <></>;
};
