import { t } from "@lingui/macro";
import { useUserSettings } from "src/core/Users/hooks/useUserSettings";
import SuggestInput, { SelectGroupItem } from "src/uiKit/SuggestInput/SuggestInput";

import DoneIcon from "@mui/icons-material/Done";
import { Button, Stack, Typography } from "@mui/material";

import { useAuth } from "../Auth/useAuth";
import {
  SupportedLanguage,
  fullEnglishLanguageName,
  fullLanguageName,
  getUserLangCode,
  supportedLanguages,
} from "./lang";

interface LanguageFormProps {
  onClose: () => void;
}

export const LanguageForm = ({ onClose }: LanguageFormProps): JSX.Element => {
  const { user } = useAuth();

  const userSettings = useUserSettings();

  const getLabelFromCode = (lang: SupportedLanguage) => {
    const englishName = fullEnglishLanguageName[lang];
    const name = fullLanguageName[lang];
    return `${englishName} (${name})`;
  };

  const currentLangCode = userSettings.uiLangCode || "en";
  const currentLang = getLabelFromCode(currentLangCode as SupportedLanguage);

  const optionsFull = supportedLanguages
    .map((lang: SupportedLanguage) => {
      return { label: getLabelFromCode(lang), value: lang };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const userCodes = getUserLangCode();
  const groups: SelectGroupItem[] = [];

  userCodes.forEach((code) => {
    const value = optionsFull.find((option) => option.value === code)?.label;
    if (!value) return;
    const groupTitle = t`System languages`;
    groups.push({ value, groupTitle });
  });

  optionsFull.forEach((option) => {
    const isUserLang = userCodes.includes(option.value);
    if (isUserLang) return;
    const groupTitle = t`Other languages`;
    groups.push({ value: option.label, groupTitle });
  });

  const options = groups.map((group) => group.value);
  const onRefresh = () => {
    window.location.reload();
  };
  const onChangeLanguage = async (value: string) => {
    const isChanging = value !== currentLang;
    if (!isChanging) {
      return;
    }

    const code = optionsFull.find((option) => option.label === value)?.value;
    if (code) {
      await userSettings.updateUserSettings({ uiLangCode: code });
      onRefresh();
    } else {
      console.log("Language not found");
    }
  };

  if (!user || userSettings.loading) {
    return <></>;
  }

  return (
    <form
      action=""
      onSubmit={(e) => {
        e.preventDefault();
        onClose();
      }}
    >
      <Stack
        sx={{
          gap: "20px",
        }}
      >
        <Typography variant="h3">{t`Language`}</Typography>
        <SuggestInput
          strict
          options={options}
          label={t`Set desired UI language.`}
          value={currentLang}
          onChange={onChangeLanguage}
          groups={userCodes.length > 0 ? groups : undefined}
        />
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack />
          <Button startIcon={<DoneIcon />} variant="contained" type="submit">
            {t`Done`}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default LanguageForm;
