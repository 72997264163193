import { FC } from "react";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Stack } from "@mui/material";

export const MagicBackground: FC = () => {
  return (
    <Stack
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
        backgroundColor: COLORS.bg,
      }}
    >
      <Stack
        sx={{
          width: "596px",
          height: "242px",
          position: "absolute",
          left: "50px",
          top: "-80px",
          borderRadius: "50%",
          opacity: 0.4,
          background:
            "linear-gradient(90deg, #d5edf9 0%, #7ed1ff 25%, #b3c5fa 50%, #cbc1f8 70%, #d8bdf6 75%)",
          filter: "blur(100px)",
          "@media (max-width: 900px)": {
            left: "0px",
          },
          "@media (max-width: 600px)": {
            width: "90vw",
          },
        }}
      />

      <Stack
        sx={{
          width: "596px",
          height: "242px",
          position: "absolute",
          right: "35px",
          top: "-80px",
          borderRadius: "50%",
          opacity: 0.7,
          background:
            "linear-gradient(90deg, #d8bdf6 0%, #cbc1f8 25%, #b3c5fa 50%, #8fe9ff 70%, #99daff 75%)",
          backgroundSize: "100% 100%",

          filter: "blur(100px)",
          "@media (max-width: 900px)": {
            right: "0px",
          },
          "@media (max-width: 600px)": {
            width: "90vw",
          },
        }}
      />
    </Stack>
  );
};
