import { Context, ReactNode, createContext, forwardRef, useContext, useState } from "react";
import { COLORS } from "src/uiKit/assets/styles/colors";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { Snackbar, Typography } from "@mui/material";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

interface NotificationParams {
  message: string;
  type: AlertColor;
  timeout?: number;
}

interface NotificationContext {
  showNotification: (options: NotificationParams) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  notificationMessage: string;
  type: AlertColor;
  timeout: number;
  setTimeout: (timeout: number) => void;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotificationContext: Context<NotificationContext> = createContext<NotificationContext>({
  showNotification: () => void 0,
  open: false,
  setOpen: () => void 0,
  notificationMessage: "",
  type: "success",
  timeout: 3000,
  setTimeout: () => void 0,
});

function useProvideNotification(): NotificationContext {
  const [open, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [type, setType] = useState<AlertColor>("success");
  const [timeout, setTimeout] = useState(3000);

  const showNotification = (options: NotificationParams) => {
    setNotificationMessage(options.message);
    setType(options.type);
    setOpen(true);

    if (options.timeout) {
      setTimeout(options.timeout);
    }
  };

  return {
    showNotification,
    open,
    setOpen,
    notificationMessage,
    type,
    timeout,
    setTimeout,
  };
}

export function NotificationProvider(props: { children: ReactNode }): JSX.Element {
  const provider = useProvideNotification();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    provider.setOpen(false);
    provider.setTimeout(3000);
  };
  const typeColors = {
    success: "#0EB514",
    error: "#F94D5C",
    info: "#056AE3",
    warning: "#FFB905",
  };

  const color = typeColors[provider.type];

  const icons = {
    success: (
      <CheckCircleIcon
        sx={{
          color: typeColors.success,
        }}
      />
    ),
    error: (
      <ErrorIcon
        sx={{
          color: typeColors.error,
        }}
      />
    ),
    info: (
      <InfoIcon
        sx={{
          color: typeColors.info,
        }}
      />
    ),
    warning: (
      <WarningIcon
        sx={{
          color: typeColors.warning,
        }}
      />
    ),
  };

  const successNotification = (
    <Snackbar
      open={provider.open}
      autoHideDuration={provider.timeout}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        icon={icons[provider.type]}
        severity={provider.type}
        sx={{
          minWidth: "min(440px, calc(100vw - 100px))",
          marginRight: "48px",
          marginBottom: "48px",
          maxHeight: "128px",
          maxWidth: "440px",
          backgroundColor: "#F9FBFC",
          borderLeft: `4px solid ${color}`,
          borderRadius: "4px",
          boxShadow: `
           rgba(0, 0, 0, 0.1) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px
          `,
          position: "relative",
          ".MuiButtonBase-root": {
            backgroundColor: COLORS["text-disabled"],
          },
        }}
      >
        <Typography
          sx={{
            color: COLORS["text-secondary"],
            marginTop: "-3px",
          }}
          variant="body1"
        >
          {provider.notificationMessage}
        </Typography>
      </Alert>
    </Snackbar>
  );

  return (
    <NotificationContext.Provider value={provider}>
      <>
        {props.children}
        {successNotification}
      </>
    </NotificationContext.Provider>
  );
}

export const useNotification = (): NotificationContext => useContext(NotificationContext);
