import { t } from "@lingui/macro";
import { doc } from "firebase/firestore";
import { Chart1, DocumentText1, LanguageSquare, LogoutCurve, Notification } from "iconsax-react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import { useAccess } from "src/core/Access/useAccess/useAccess";
import { isHiringApp, isProfolioApp } from "src/core/AppConfig/appConfig";
import { useMessages } from "src/core/Messages/useMessages/useMessages";
import { isDevEnvironment } from "src/core/Url/url";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";

import { useAuth } from "../Auth/useAuth";
import db from "../Backend/firestore";
import { GLOBAL_MODALS } from "../Modal/data";
import { TEST_SELECTOR } from "../Tests/selectors";
import { useQuery } from "../Url/useQuery";
import BaseHeader, { HeaderMenuElement } from "./BaseHeader";

export default function UserHeader(): JSX.Element {
  const { logout } = useAuth();
  const { uid, isSuperAdmin } = useAuth();
  const access = useAccess();
  const { users, isClientForHiring } = useUsers();
  const navigate = useNavigate();
  const urlQuery = useQuery();
  const userName = users[uid]?.fullName || "";

  const [userInfoDB] = useDocumentData(doc(db.collections.users, uid));

  const { myMessages } = useMessages();

  const unreadMessages = myMessages.filter(
    (message) => !message.data.readByUserIds?.includes(uid),
  ).length;

  const settingsMenu: HeaderMenuElement[] = [];

  const dashboardMenu: HeaderMenuElement = {
    linkTo: "/",
    title: t`Dashboard`,
    type: "Tab",
    testDataId: TEST_SELECTOR.header.dashboardMenu,
  };

  const pages: HeaderMenuElement[] = [];

  const companyInfoMenu: HeaderMenuElement = {
    linkTo: "/settings/company-info",
    title: t`Company Info`,
    type: "SubMenu",
    testDataId: TEST_SELECTOR.header.companyInfoMenu,
  };

  const portfolioDesignCustomizationMenu: HeaderMenuElement = {
    linkTo: "/portfolio-design-editor",
    title: t`Design Customization`,
    type: "SubMenu",
  };

  const companyStructureMenu: HeaderMenuElement = {
    linkTo: "/company-structure",
    activeLinksTo: ["/company-structure/"],
    title: t`Company Structure`,
    type: "Tab",
  };

  const usersMenu: HeaderMenuElement = {
    title: t`Users`,
    activeLinksTo: ["/users", "/invite", "/user-invite-employee", "/user-invite-client"],
    type: "Tab",
    linkTo: `/users`,
  };

  const systemRolesMenu: HeaderMenuElement = {
    title: t`System Roles`,
    type: "Tab",
    linkTo: `/system-roles`,
  };

  const vacancyListMenu: HeaderMenuElement = {
    linkTo: "/vacancy-list",
    activeLinksTo: ["/vacancy-cv-board/", "/vacancy-view/", "/vacancy-editor/"],
    title: t`Vacancies`,
    type: "Tab",
  };
  const cvMenu: HeaderMenuElement = {
    linkTo: "/cv-list",
    title: t`Candidates`,
    activeLinksTo: ["/cv-editor/"],
    type: "Tab",
  };

  const hiringClients: HeaderMenuElement = {
    linkTo: `/clientCompany-list`,
    title: t`Clients`,
    activeLinksTo: ["/clientCompany-editor/"],
    type: "Tab",
  };
  const hiringPipelineMenu: HeaderMenuElement = {
    linkTo: "/hiring-pipeline",
    activeLinksTo: ["/hiring-pipeline/"],
    title: t`Hiring Pipelines`,
    type: "Tab",
  };
  const showcaseMenu: HeaderMenuElement = {
    linkTo: "/showcases",
    title: t`Showcases`,
    type: "SubMenu",
    testDataId: TEST_SELECTOR.header.showcasePage,
  };
  const portfolioMenu: HeaderMenuElement = {
    linkTo: "/portfolios",
    title: t`Portfolios`,
    type: "SubMenu",
  };
  const sharedPortfolioMenu: HeaderMenuElement = {
    linkTo: "/sharedLinks-list",
    title: t`Shared Links`,
    type: "SubMenu",
  };

  if (isHiringApp) {
    pages.push(dashboardMenu);
    if (access.viewVacancies) {
      pages.push(vacancyListMenu);
    }

    if (access.viewCvBase) {
      pages.push(cvMenu);
    }

    if (access.viewClients) {
      pages.push(hiringClients);
    }

    if (access.viewGlobalHiringPipeline) {
      pages.push(hiringPipelineMenu);
    }
  } else if (isProfolioApp) {
    pages.push(dashboardMenu);
    if (access.viewOwnShowcases || access.viewOtherShowcases) {
      pages.push(showcaseMenu);
    }

    if (access.viewPortfolio) {
      pages.push(portfolioMenu);
    }

    if (access.sharePortfolioLink) {
      pages.push(sharedPortfolioMenu);
    }

    if (access.sharePortfolioLink) {
      pages.push(portfolioDesignCustomizationMenu);
    }
  }

  const settingsParentMenu: HeaderMenuElement = {
    title: t`Organization`,
    activeLinksTo: ["/company-structure/", "/users?", "/system-roles/"],
    type: "Tab",
    testDataId: TEST_SELECTOR.header.companyInfoDropdown,
    child: [],
  };
  if (access.setCompanyInfo) {
    settingsParentMenu.child?.push(companyInfoMenu);
  }

  if (access.setDepartment) {
    settingsParentMenu.child?.push(companyStructureMenu);
  }

  if (access.viewUsers) {
    settingsParentMenu.child?.push(usersMenu);
  }

  if (access.setGlobalSystemRoles) {
    settingsParentMenu.child?.push(systemRolesMenu);
  }

  if (settingsParentMenu.child?.length) {
    pages.push(settingsParentMenu);
  }

  if (userInfoDB) {
    settingsMenu.push({
      title: t`My Profile`,
      linkTo: `?${GLOBAL_MODALS.MY_PROFILE}=true`,
      handler: () => {
        const newQuery = new URLSearchParams(urlQuery);
        newQuery.set(GLOBAL_MODALS.MY_PROFILE, "true");
        navigate(`?${newQuery.toString()}`);
      },
      type: "MenuRow",
    });
  }

  if (userInfoDB) {
    settingsMenu.push({
      title: t`Language`,
      linkTo: `?${GLOBAL_MODALS.LANGUAGE}=true`,
      handler: () => {
        const newQuery = new URLSearchParams(urlQuery);
        newQuery.set(GLOBAL_MODALS.LANGUAGE, "true");
        navigate(`?${newQuery.toString()}`);
      },
      type: "MenuRow",
      icon: <LanguageSquare size={"18px"} />,
    });
  }

  if (userInfoDB) {
    settingsMenu.push({
      title: t`Change Password`,
      linkTo: `?${GLOBAL_MODALS.CHANGE_PASSWORD}=true`,
      handler: () => {
        const newQuery = new URLSearchParams(urlQuery);
        newQuery.set(GLOBAL_MODALS.CHANGE_PASSWORD, "true");
        navigate(`?${newQuery.toString()}`);
      },
      type: "MenuRow",
    });
  }

  if (isDevEnvironment) {
    settingsMenu.push({
      title: t`Dev settings`,
      linkTo: "/settings/security",
      type: "MenuRow",
    });
  }

  if (userInfoDB) {
    settingsMenu.push({
      title: t`Notifications`,
      linkTo: `?${GLOBAL_MODALS.NOTIFICATIONS}=true`,
      handler: () => {
        const newQuery = new URLSearchParams(urlQuery);
        newQuery.set(GLOBAL_MODALS.NOTIFICATIONS, "true");
        navigate(`?${newQuery.toString()}`);
      },
      type: "MenuRow",
      icon: <Notification size={"18px"} />,
    });
  }

  if (access.viewUsage) {
    settingsMenu.push({
      title: t`Billing`,
      linkTo: "/billing",
      type: "MenuRow",
    });

    settingsMenu.push({
      title: t`Usage`,
      linkTo: "/usage",
      type: "MenuRow",
      icon: <Chart1 size={"18px"} />,
    });
  }

  settingsMenu.push({
    title: t`Help center`,
    linkTo: "https://prohiring.gitbook.io/",
    type: "MenuRow",
    icon: <DocumentText1 size={"18px"} />,
  });

  settingsMenu.push({
    title: t`Logout`,
    handler: logout,
    type: "MenuRow",
    icon: <LogoutCurve size={"18px"} />,
  });

  const avatarUrl = isSuperAdmin ? "/superAdminLogo.png" : userInfoDB?.photoURL || "";
  return (
    <BaseHeader
      rightHeaderMenuItems={pages}
      userSettingsMenu={settingsMenu}
      avatarURL={avatarUrl}
      showSearch={!isClientForHiring}
      viewBreakpointWidth={isProfolioApp ? 1200 : 960}
      showNotifications
      notificationBadge={unreadMessages > 9 ? `9+` : unreadMessages ? `${unreadMessages}` : ""}
      showAppRouterButton={!isClientForHiring}
      userName={userName}
      userEmail={users[uid]?.email || ""}
    />
  );
}
