import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Box, Modal } from "@mui/material";

import { COLORS } from "../assets/styles/colors";
import { ModalBox } from "./style";

interface ConfirmModal {
  isOpen: boolean;
  title: string;
  content: JSX.Element;
  confirmButtonTitle: string;
  onConfirm: () => void;
  cancelButtonTitle?: string;
  onCancel: (value: boolean) => void;
  skipMessage?: string;
  skipDefault?: boolean;
  onSkipChange?: (skip: boolean) => void;
}

export default function ConfirmModal({
  isOpen,
  title,
  content,
  confirmButtonTitle,
  onConfirm,
  cancelButtonTitle,
  onCancel,
  skipMessage,
  skipDefault,
  onSkipChange,
}: ConfirmModal) {
  const handleClose = () => {
    onCancel(false);
  };

  const [accepted, changeAccept] = useState(skipDefault || false);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBox>
        <CloseIcon
          sx={{
            position: "absolute",
            top: 15,
            right: 15,
            color: COLORS["text-disabled"],
          }}
          onClick={handleClose}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "2rem",
          }}
        >
          <Typography id="modal-modal-title" variant="h3">
            {title}
          </Typography>
          <Box id="modal-modal-description">{content}</Box>
          {skipMessage && (
            <FormControlLabel
              sx={{ mb: 2 }}
              control={
                <Checkbox
                  checked={accepted}
                  onChange={() => {
                    changeAccept(!accepted);
                    onSkipChange && onSkipChange(!accepted);
                  }}
                />
              }
              label={skipMessage}
            />
          )}
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Button variant={"contained"} onClick={onConfirm}>
              {confirmButtonTitle}
            </Button>
            {cancelButtonTitle && (
              <Button variant={"outlined"} onClick={handleClose}>
                {cancelButtonTitle}
              </Button>
            )}
          </Box>
        </Box>
      </ModalBox>
    </Modal>
  );
}
