import { deleteDoc, doc, query, setDoc, where } from "firebase/firestore";
import { useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { appType } from "src/core/AppConfig/appConfig";

import { Message } from "@common/message.types";

import { MessageDoc } from "@server/api/messages/types";

import { useAuth } from "../../Auth/useAuth";
import db from "../../Backend/firestore";
import { MessagesContext } from "./messagesContext";

export function useProviderMessages(): MessagesContext {
  const auth = useAuth();
  const [allMessages] = useCollection(
    auth.uid ? query(db.collections.messages, where("appType", "==", appType)) : null,
  );

  const messages = useMemo(
    () =>
      allMessages?.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      })) || [],
    [allMessages],
  );

  const myMessages = useMemo(
    () =>
      messages
        .filter(
          (message) =>
            message.data.ccUserIds.includes(auth.uid) ||
            message.data.authorUserId === auth.uid ||
            message.data?.ccEmails?.includes(auth.userInfo.email || ""),
        )
        .filter((message) => !message.data.isDraft || message.data.authorUserId === auth.uid)
        .sort((a, b) => b.data.createdAt - a.data.createdAt),
    [messages, auth.uid, auth.userInfo.email],
  );

  const deleteMessage = async (messageId: string) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this message?");
    if (!confirmDelete) {
      return;
    }

    await deleteDoc(doc(db.collections.messages, messageId));
  };

  const createMessage = async (newMessage: Message) => {
    const uid = doc(db.collections.messages);
    await setDoc(uid, newMessage);
  };

  const updateMessage = async (messageId: string, message: Message) => {
    await setDoc(doc(db.collections.messages, messageId), message);
  };

  const markMessageAsRead = async (messageData: MessageDoc) => {
    if (!isNewMessage(messageData)) return;
    const readByUserIds = messageData.data.readByUserIds || [];
    await setDoc(
      doc(db.collections.messages, messageData.id),
      {
        ...messageData,
        readByUserIds: [...readByUserIds, auth.uid],
      },
      {
        merge: true,
      },
    );
  };

  const isNewMessage = (messageData: MessageDoc) => {
    const readByUserIds = messageData.data.readByUserIds || [];
    return !readByUserIds.includes(auth.uid);
  };

  return {
    myMessages,
    markMessageAsRead,
    isNewMessage,
    messages,
    deleteMessage,
    createMessage,
    updateMessage,
  };
}
