import { t } from "@lingui/macro";
import dayjs from "dayjs";
import { limit, orderBy, query, where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useAuth } from "src/core/Auth/useAuth";
import db from "src/core/Backend/firestore";
import { DAY } from "src/core/Utils/time";
import { useCvPreview } from "src/prohiring/Candidates/usePreviewCv/useCvPreview";
import { getFullVacancyTitle } from "src/prohiring/Vacancy/lib/getFullVacancyTitle";

import { ActionInfo, ActionUrgencyLevel, VacancyStatus } from "@common/vacancy.types";

import { useVacancies } from "../../Vacancy/hooks/useVacancies/useVacancies";
import { HiringFeedContext } from "./useHiringFeedContext";

const getUrgencyByDeadline = (deadlineTimestamp: number): ActionUrgencyLevel => {
  let urgency: ActionUrgencyLevel = "low";
  const timeLeft = deadlineTimestamp - Date.now();
  if (timeLeft <= 1 * DAY) {
    urgency = "hight";
  } else if (timeLeft < 3 * DAY) {
    urgency = "middle";
  }

  return urgency;
};

const getTimeLeftString = (deadlineTimestamp: number): string => {
  return dayjs(deadlineTimestamp).fromNow();
};

const clientResponsibleStatuses: VacancyStatus[] = ["Review"];

export function useProviderHiringFeed(): HiringFeedContext {
  const urgencyVacancyStatus: Record<VacancyStatus, number | null> = {
    Draft: 5 * DAY,
    Review: 4 * DAY,
    "Need to improve": 4 * DAY,
    Approved: 4 * DAY,
    Closed: null,
  };

  const auth = useAuth();
  const [listOfVacancies, loadingVacancies] = useVacancies();
  const cvPreview = useCvPreview();
  const myEmail = auth.userInfo.email || "";
  const pageCount = 10;
  const [limitCandidates, setLimitCandidates] = useState(5);
  const showMore = () => setLimitCandidates((prev) => prev + pageCount);
  const actionMessageStatus: Record<VacancyStatus, string | null> = {
    Draft: t`Send vacancy to review`,
    Review: t`Leave feedback on vacancy`,
    "Need to improve": t`Improve vacancy`,
    Approved: t`Publish vacancy`,
    Closed: null,
  };

  const clientsVacancies = listOfVacancies.filter((vacancy) => {
    const clientsEmails =
      vacancy.data.clientType === "Internal"
        ? vacancy.data.responsibleInternalCustomersEmails || []
        : vacancy.data.responsibleCustomersEmails;

    return clientsEmails.find((email) => email.toLowerCase() === myEmail.toLowerCase());
  });

  const clientActions = clientsVacancies
    .filter((vacancy) => clientResponsibleStatuses.includes(vacancy.data.status))
    .filter((vacancy) => actionMessageStatus[vacancy.data.status])
    .map(({ data, id }) => {
      const status = data.status;
      const actionTitle = actionMessageStatus[status] || t`Unknown action`;
      const deadline = data.updatedAt + (urgencyVacancyStatus[status] || 0);

      const actionItem: ActionInfo = {
        title: actionTitle,
        subTitle: getFullVacancyTitle(data),
        captionInfo: "",
        timeLeftString: getTimeLeftString(deadline),
        timeLeft: deadline - Date.now(),
        urgency: getUrgencyByDeadline(deadline),
        redirectUrlPath: `/vacancy-editor/${id}`,
      };
      return actionItem;
    });

  const recruitersVacancies = listOfVacancies.filter((vacancy) => {
    return vacancy.data.responsibleRecruitersEmails.includes(myEmail);
  });

  const recruitersVacanciesIds = recruitersVacancies.map((vacancy) => vacancy.id);
  const candidatesQuery = useMemo(() => {
    if (recruitersVacanciesIds.length === 0) return null;
    return query(
      db.collections.vacancyCandidates,
      where("vacancyId", "in", recruitersVacanciesIds),
      where("deadlineAt", "!=", -1),
      where("deadlineAt", "<", Date.now() + 4 * DAY),
      orderBy("deadlineAt"),
      limit(limitCandidates),
    );
  }, [recruitersVacanciesIds.length, limitCandidates]);
  const [candidatesRequest] = useCollection(candidatesQuery);

  const cvToLoad: string[] = [];

  const candidates = useMemo(
    () => candidatesRequest?.docs?.map((c) => c.data()) || [],
    [candidatesRequest],
  );

  const candidateActions: ActionInfo[] = candidates
    .map((candidate) => {
      const cvId = candidate.cvId;
      const vacancy = recruitersVacancies.find((vacancy) => vacancy.id === candidate.vacancyId);
      if (!vacancy) return;
      if (!cvToLoad.includes(cvId)) cvToLoad.push(cvId);

      const vacancyTitle = getFullVacancyTitle(vacancy.data);
      const candidateTitle = candidate.cvPreview?.name || t`Candidate`;

      const action: ActionInfo = {
        title: `${candidateTitle}`,
        subTitle: vacancyTitle,
        captionInfo: t`Finish: ${candidate.hiringStep}`,
        timeLeftString: getTimeLeftString(candidate.deadlineAt),
        timeLeft: candidate.deadlineAt - Date.now(),
        urgency: getUrgencyByDeadline(candidate.deadlineAt),
        redirectUrlPath: `/vacancy-cv-board/${vacancy.id}?cvId=${cvId}`,
      };
      return action;
    })
    .filter((action) => action) as ActionInfo[];

  const recruitersVacancyStatusActions = recruitersVacancies
    .filter((vacancy) => !clientResponsibleStatuses.includes(vacancy.data.status))
    .filter((vacancy) => actionMessageStatus[vacancy.data.status])
    .filter((vacancy) => {
      const isApproved = vacancy.data.status === "Approved";
      const isPublished = vacancy.data.publishedLinks.length > 0;
      const isApprovedAndPublished = isApproved && isPublished;
      return !isApprovedAndPublished;
    })
    .map(({ data, id }) => {
      const status = data.status;
      const actionTitle = actionMessageStatus[status] || t`Unknown action`;
      const deadline = data.updatedAt + (urgencyVacancyStatus[status] || 0);

      const actionItem: ActionInfo = {
        title: actionTitle,
        subTitle: getFullVacancyTitle(data),
        captionInfo: "",
        timeLeftString: getTimeLeftString(deadline),
        timeLeft: deadline - Date.now(),
        urgency: getUrgencyByDeadline(deadline),
        redirectUrlPath: `/vacancy-editor/${id}`,
      };
      return actionItem;
    });

  const getCandidateName = (cvId: string) => {
    const cvPreviewData = cvPreview.cvData[cvId];
    return cvPreviewData?.name || cvPreviewData?.email || t`Candidate`;
  };

  const remindersActions: ActionInfo[] = [];
  recruitersVacancies.forEach((vacancy) => {
    const reminders = (vacancy.data.reminders || [])
      .filter((reminder) => !reminder.isRead)
      .filter((reminder) => reminder.emailTo.includes(myEmail));

    reminders.map((reminder) => {
      const actionItem: ActionInfo = {
        title: "Reminder: " + getCandidateName(reminder.candidateCvId),
        subTitle: getFullVacancyTitle(vacancy.data),
        captionInfo: reminder.message,
        timeLeftString: getTimeLeftString(reminder.eventAt),
        timeLeft: reminder.eventAt - Date.now(),
        urgency: getUrgencyByDeadline(reminder.eventAt),
        redirectUrlPath: `/vacancy-cv-board/${vacancy.id}?showReminderCvId=${reminder.candidateCvId}`,
      };
      const cvId = reminder.candidateCvId;
      if (!cvToLoad.includes(cvId)) cvToLoad.push(cvId);

      remindersActions.push(actionItem);
    });
  });

  useEffect(() => {
    cvPreview.getCvData(cvToLoad);
  }, [cvToLoad.length]);

  return {
    loading: loadingVacancies,
    actionInfo: [
      ...candidateActions,
      ...remindersActions,
      ...clientActions,
      ...recruitersVacancyStatusActions,
    ],

    showMore,
    isFullLoaded: candidates.length < limitCandidates,
  };
}
