import { useLocation } from "react-router-dom";

import { useAuth } from "../Auth/useAuth";
import StaticHeader from "./StaticHeader";
import UserHeader from "./UserHeader";

export function Header(): JSX.Element {
  const { isAuthorized } = useAuth();
  const location = useLocation();
  const skipHeaderUrls = ["/portfolio/", "/sharedLink/"];
  const isNeedToSkipHeader = skipHeaderUrls.some((url) => location.pathname.startsWith(url));
  if (isNeedToSkipHeader) {
    return <></>;
  }
  return isAuthorized ? <UserHeader /> : <StaticHeader />;
}
