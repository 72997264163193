import { Context, createContext } from "react";

export interface UsageContext {
  loading: boolean;
  isLimited: boolean;
  isWarning: boolean;
  limitedMessage: string;
  isLimitReached: boolean;
  isUnpaidBill: boolean;
}

export const usageContext: Context<UsageContext> = createContext<UsageContext>({
  loading: false,
  isLimited: false,
  isWarning: false,
  limitedMessage: "",
  isLimitReached: false,
  isUnpaidBill: false,
});
