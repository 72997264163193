import { doc, updateDoc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Loader } from "src/uiKit/Loader/Loader";
import { CustomModal } from "src/uiKit/Modal/CustomModal";

import { UserInfo } from "@common/user.types";

import { useAuth } from "../../Auth/useAuth";
import db from "../../Backend/firestore";
import { MyProfileForm } from "./MyProfileForm";

interface MyProfileModalProps {
  onClose: () => void;
}

export function MyProfileModal({ onClose }: MyProfileModalProps): JSX.Element {
  const { uid } = useAuth();
  const [userInfoDB, loading] = useDocumentData(uid ? doc(db.collections.users, uid) : null);

  if (!uid || !userInfoDB || loading) {
    return <Loader />;
  }

  const updateProfile = async (userInfo: Partial<UserInfo>) => {
    await updateDoc(doc(db.collections.users, uid), userInfo);
  };

  return (
    <CustomModal onClose={onClose} width="min(100vw, 600px)">
      <MyProfileForm
        userInfo={userInfoDB}
        myUserId={uid}
        update={updateProfile}
        onClose={onClose}
      />
    </CustomModal>
  );
}
