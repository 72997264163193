import styled from "@emotion/styled";

import { Box, Typography } from "@mui/material";

import { COLORS } from "../assets/styles/colors";

export const TextAreaInputWrapper = styled(Box)({
  width: "100%",
});

export const CounterSymbol = styled(Typography)({
  color: COLORS["text-label"],
  fontSize: "0.875rem",
  lineHeight: "143%",
});

export const HelperTextWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
