import * as Sentry from "@sentry/react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { convertImage } from "src/core/Images/convertImage";
import { isImageName } from "src/core/Utils/fileNameCheck";

import { storage } from "./index";

export async function uploadFile(path: string, fileToUpdate: File) {
  try {
    if (isImageName(fileToUpdate.name)) {
      fileToUpdate = await convertImage(fileToUpdate);
    }
  } catch (e) {
    console.error("Failed to convert image", e);
    Sentry.captureException(e, {
      data: {
        title: "Failed to convert image",
        path,
        fileToUpdate,
      },
    });
  }

  const storageRef = ref(storage, path);
  const uploadTask = await uploadBytes(storageRef, fileToUpdate);
  return await getDownloadURL(uploadTask.ref);
}
