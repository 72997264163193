export function stringToColor(string: string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  const color = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    color[i] = (hash >> (i * 8)) & 0xff;
  }

  const hsl = rgbToHSL(color[0], color[1], color[2]);
  hsl[1] = 60;
  hsl[2] = 17;

  // Convert back to RGB then to a string
  const neutralRGB = hslToRGB(hsl[0], hsl[1], hsl[2]);
  let neutralColor = "#";
  for (let i = 0; i < 3; i++) {
    neutralColor += `00${neutralRGB[i].toString(16)}`.slice(-2);
  }

  return neutralColor;
}

function rgbToHSL(r: number, g: number, b: number): [number, number, number] {
  r /= 255;
  g /= 255;
  b /= 255;
  const max: number = Math.max(r, g, b);
  const min: number = Math.min(r, g, b);
  let h: number = 0;
  let s: number = 0;
  const l: number = (max + min) / 2;

  if (max !== min) {
    const d: number = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    if (max === r) {
      h = (g - b) / d + (g < b ? 6 : 0);
    } else if (max === g) {
      h = (b - r) / d + 2;
    } else if (max === b) {
      h = (r - g) / d + 4;
    }
    h /= 6;
  }

  return [h, s, l];
}

function hue2rgb(p: number, q: number, t: number): number {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

function hslToRGB(h: number, s: number, l: number): [number, number, number] {
  let r: number, g: number, b: number;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q: number = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p: number = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}
