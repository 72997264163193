// Example of using Converter:
// 1000 ==> 1KB

export function convertBytes(sizeInBytes: number) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let unitsPosition = 0,
    bytes = parseInt(String(sizeInBytes), 10) || 0;

  while (bytes >= 1024 && ++unitsPosition) {
    bytes = bytes / 1024;
  }
  return bytes.toFixed(bytes < 10 && unitsPosition > 0 ? 1 : 0) + " " + units[unitsPosition];
}
