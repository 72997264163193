import { CustomModal } from "src/uiKit/Modal/CustomModal";

import { NotificationsSettingsForm } from "./NotificationsSettingsForm";

interface NotificationsSettingsModalProps {
  onClose: () => void;
}

export function NotificationsSettingsModal({
  onClose,
}: NotificationsSettingsModalProps): JSX.Element {
  return (
    <CustomModal onClose={onClose}>
      <NotificationsSettingsForm onClose={onClose} />
    </CustomModal>
  );
}
