import { DocumentReference, setDoc } from "firebase/firestore";
import { Context, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
  activateUserInternationalization,
  currentLanguage,
  defaultLanguage,
  localStorageLangCode,
} from "src/core/Language/lang";

import { UserSettings } from "@common/user.types";

import { useAuth } from "../../Auth/useAuth";
import db from "../../Backend/firestore";

interface UserSettingsContext extends UserSettings {
  loading: boolean;
  updateUserSettings: (newSettings: Partial<UserSettings>) => Promise<void>;
}

const userSettingsContext: Context<UserSettingsContext> = createContext<UserSettingsContext>({
  loading: false,
  showShareImageDialog: true,
  updateUserSettings: () => Promise.resolve(),
});

function useProviderUserSettings(): UserSettingsContext {
  const [dataPoint, setDataPoint] = useState<DocumentReference<UserSettings>>();
  const { uid } = useAuth();
  useEffect(() => {
    if (dataPoint || !uid) {
      return;
    } else {
      setDataPoint(db.getUserSettingsDataPoint(uid));
    }
  }, [dataPoint, uid]);
  const [userSettings, loading] = useDocumentData(dataPoint);

  useEffect(() => {
    const langCode = userSettings?.uiLangCode || localStorageLangCode || defaultLanguage;
    if (currentLanguage !== langCode) {
      activateUserInternationalization(langCode);
    }
  }, [userSettings?.uiLangCode]);

  return {
    ...userSettings,
    uiLangCode: userSettings?.uiLangCode || localStorageLangCode || defaultLanguage,
    loading: !dataPoint || loading,
    showShareImageDialog: userSettings?.showShareImageDialog ?? true,
    emailMessages: userSettings?.emailMessages ?? "Instantly",
    reminders: userSettings?.reminders ?? "On",
    vacancyApproval: userSettings?.vacancyApproval ?? "On",
    updateUserSettings: async (data) => {
      if (!dataPoint) {
        return;
      }
      await setDoc(dataPoint, data, { merge: true });
    },
  };
}

export function UserSettingsProvider(props: { children: ReactNode }): JSX.Element {
  const userSettings = useProviderUserSettings();
  return (
    <userSettingsContext.Provider value={userSettings}>
      {props.children}
    </userSettingsContext.Provider>
  );
}
export const useUserSettings = (): UserSettingsContext => useContext(userSettingsContext);
