import { t } from "@lingui/macro";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { htmlToText } from "html-to-text";
import { useEffect, useMemo, useState } from "react";
import { uploadFile } from "src/core/Backend/uploadFile";
import { useMessages } from "src/core/Messages/useMessages/useMessages";
import { companyName } from "src/core/Url/url";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";
import { uniq } from "src/core/Utils/array";
import { MB } from "src/core/Utils/bytes";
import { isImageName } from "src/core/Utils/fileNameCheck";
import ColorTextAvatar from "src/uiKit/Avatar/ColorTextAvatar";
import { FileUploadButton } from "src/uiKit/FileUploadButton/FileUploadButton";
import { UploadedFile } from "src/uiKit/FilesList/UploadedFile";
import { MultiValueInput, MultiValueOption } from "src/uiKit/Input/MultiValueInput/MultiValueInput";
import RichTextEditor from "src/uiKit/RichText/RichTextEditor";
import RichTextViewer from "src/uiKit/RichText/RichTextViewer";
import { TextAreaInput } from "src/uiKit/TextAreaInput/TextAreaInput";
import { COLORS } from "src/uiKit/assets/styles/colors";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import { AppType } from "@common/appType";
import { Message, MessageAttachment, MessageRoomType } from "@common/message.types";

import { useAuth } from "../Auth/useAuth";
import { CommentContainer, SendMessageForm } from "./style";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface CommentsProps {
  roomId?: string;
  roomType?: MessageRoomType;
  canComment?: boolean;
  canAttachFiles?: boolean;
  ccUserIds?: string[];
  ccEmails?: string[];
  showCC?: boolean;
  limitMessagesCount?: number;
  draftByDefault?: boolean;
  placeholder?: string;
  emptyMessage?: string;
  richText?: boolean;
  vacancyId?: string;
  cvId?: string;
  appType: AppType;
  isSupportTeam?: boolean;
}

export const Comments = ({
  canComment,
  roomId,
  roomType,
  ccUserIds,
  ccEmails,
  showCC,
  limitMessagesCount,
  draftByDefault,
  placeholder,
  emptyMessage,
  richText,
  vacancyId,
  cvId,
  canAttachFiles,
  appType,
  isSupportTeam,
}: CommentsProps) => {
  const [commentInput, setCommentInput] = useState("");
  const [commentAttachments, setCommentAttachments] = useState<MessageAttachment[]>([]);

  const commentStorageKey = "Prohiring_CommentStorageKey" + roomId + roomType;
  const commentEditIdStorageKey =
    "Prohiring_CommentStorageKey" + roomId + roomType + "_editCommentId";
  const commentId = `${roomId}_${roomType}`;

  const editCommentInput = (comment: string) => {
    setCommentInput(comment);
    const storageInfo = localStorage.getItem(commentStorageKey);
    const comments = storageInfo ? JSON.parse(storageInfo) : {};

    if (comment) {
      comments[commentId] = comment;
    } else {
      delete comments[commentId];
    }

    localStorage.setItem(commentStorageKey, JSON.stringify(comments));
  };

  const getCommentFromStorage = () => {
    const storageInfo = localStorage.getItem(commentStorageKey);
    const comments = storageInfo ? JSON.parse(storageInfo) : {};
    return comments[commentId] || "";
  };

  useEffect(() => {
    setCommentInput(getCommentFromStorage());
  }, [roomId, commentId]);

  const [messagesForDisplayCount, setMessagesForDisplayCount] = useState(limitMessagesCount);
  const getEditMessageIdFromStorage = () => {
    const commentId = localStorage.getItem(commentEditIdStorageKey);
    return commentId;
  };

  const [editMessageId, setEditMessageId] = useState<string | null>(getEditMessageIdFromStorage());

  const setEditMessageIdToStorage = (commentId: string | null) => {
    localStorage.setItem(commentEditIdStorageKey, commentId || "");
  };

  const { users } = useUsers();
  const auth = useAuth();
  const { deleteMessage, createMessage, messages, updateMessage, markMessageAsRead, isNewMessage } =
    useMessages();

  const allMessages = useMemo(
    () =>
      messages?.filter((message) => {
        const isCorrectRoom = message.data.roomId === roomId && message.data.roomType === roomType;
        if (!isCorrectRoom) {
          return false;
        }

        return !message.data.isDraft || message.data.authorUserId === auth.uid;
      }) || [],
    [messages, roomId, roomType, auth.uid],
  );

  useEffect(() => {
    if (allMessages.length) {
      allMessages.forEach((message) => markMessageAsRead(message));
    }
  }, [allMessages]);

  const usersOptions: MultiValueOption[] = [];

  Object.keys(users).forEach((userId) => {
    const user = userId ? users[userId] : null;

    if (!user || user.accessSystemRole === "client") {
      return;
    }

    const title = [user.fullName || "", user.email || ""]
      .filter((info) => info)
      .join(" ・ ")
      .trim();

    usersOptions.push({
      title: title,
      value: userId,
      description: user.email,
    });
  });

  const [ccUsers, setCcUsers] = useState<MultiValueOption[]>([]);

  const editMessageHandler = (messageId: string, content: string) => {
    const message = allMessages.find((message) => message.id === messageId);
    if (!message) {
      return;
    }

    const textContent = richText ? htmlToText(content) : content;
    const richTextContent = richText ? content : "";

    const newMessage: Message = {
      ...message.data,
      content: textContent,
      richTextContent: richTextContent,
      updatedAt: Date.now(),
    };

    updateMessage(messageId, newMessage);
    setEditMessageId(null);
    setEditMessageIdToStorage(null);
    editCommentInput("");
  };

  const createMessageHandler = (prefixMessage: string) => {
    if (editMessageId) {
      editMessageHandler(editMessageId, commentInput);

      return;
    }

    const ccUserIds = ccUsers.map((ccUser) => ccUser.value);
    if (!roomId || !roomType) {
      return;
    }
    const partsOfMessage = [prefixMessage, commentInput];
    const content = partsOfMessage.filter((part) => part).join("\n --- \n");

    const textContent = richText ? htmlToText(content) : content;
    const richTextContent = richText ? content : "";

    const newMessage: Message = {
      authorUserId: auth.uid,
      content: textContent,
      richTextContent: richTextContent,
      roomId,
      roomType,
      ccUserIds,
      ccEmails: ccEmails || [],
      isDraft: draftByDefault || false,
      createdAt: Date.now(),
      attachments: commentAttachments,
      appType,
      isSupportTeam: !!isSupportTeam,
      readByUserIds: [auth.uid],
    };

    if (vacancyId) {
      newMessage.vacancyId = vacancyId;
    }

    if (cvId) {
      newMessage.cvId = cvId;
    }

    createMessage(newMessage);
    editCommentInput("");
    setCommentAttachments([]);
  };

  const addMentionedUserId = (userId: string) => {
    setCcUsers((oldCcUsers) => {
      const user = users[userId];
      if (!user) {
        return oldCcUsers;
      }

      const isMentioned = oldCcUsers.find((ccUser) => ccUser.value === userId);
      const isMyself = userId === auth.uid;
      if (isMentioned || isMyself) {
        return oldCcUsers;
      }
      const title = [user.fullName || "", user.email || ""]
        .filter((info) => info)
        .join(" ・ ")
        .trim();

      return [
        ...oldCcUsers,
        {
          title: title,
          value: userId,
          description: user.email,
        },
      ];
    });
  };

  useEffect(() => {
    if (ccUserIds) {
      ccUserIds.forEach((ccUserId) => {
        addMentionedUserId(ccUserId);
      });
    }
  }, [ccUserIds, users]);

  const allCCUserIdsFromMessages = allMessages
    .map((message) => {
      return message.data.ccUserIds;
    })
    .flat();

  const allMessageUsers = allMessages.map((message) => message.data.authorUserId);

  const usersIdsFromMessages = uniq([...allCCUserIdsFromMessages, ...allMessageUsers]);

  useEffect(() => {
    if (!users) {
      return;
    }

    usersIdsFromMessages.forEach((ccUserId) => {
      addMentionedUserId(ccUserId);
    });
  }, [usersIdsFromMessages.length, Object.keys(users).length]);

  const sortedMessages = allMessages.sort((a, b) => a.data.createdAt - b.data.createdAt);

  const startIndex = messagesForDisplayCount ? sortedMessages.length - messagesForDisplayCount : 0;
  const messagesForDisplay = !limitMessagesCount
    ? sortedMessages
    : sortedMessages.filter((_, index) => index >= startIndex);

  const shouldShowMore =
    limitMessagesCount && messagesForDisplayCount && allMessages.length > messagesForDisplay.length;

  const isEmpty = messagesForDisplay.length === 0;

  const [isUploading, setIsUploading] = useState(false);

  const processOneFile = async (file: File) => {
    if (!companyName) {
      return;
    }
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1];
    const randomPart = `${Math.round(Math.random() * 100000)}`;
    const filePathInStorage = `${companyName}/commentsAttachments/${Date.now()}_${randomPart}.${fileExtension}`;
    const cvUrl = await uploadFile(filePathInStorage, file);

    const messageAttachment: MessageAttachment = {
      name: file.name,
      url: cvUrl,
      size: file.size,
      extension: fileExtension,
    };

    return messageAttachment;
  };

  const uploadBunch = async (files: File[]) => {
    setIsUploading(true);
    const attachments = await Promise.all(files.map((file) => processOneFile(file)));
    const filteredAttachments = attachments.filter(
      (attachment) => attachment,
    ) as MessageAttachment[];
    setCommentAttachments((oldAttachments) => [...oldAttachments, ...filteredAttachments]);
    setIsUploading(false);
  };

  const onDeleteFile = (fileName: string) => {
    setCommentAttachments((oldAttachments) => {
      const newAttachments = oldAttachments.filter((attachment) => attachment.name !== fileName);
      return newAttachments;
    });
  };

  return (
    <>
      <Stack gap="10px" sx={{ paddingTop: "10px" }}>
        {shouldShowMore && (
          <Button
            variant="text"
            startIcon={<ArrowDropUpIcon />}
            onClick={() => {
              setMessagesForDisplayCount(messagesForDisplayCount + limitMessagesCount);
            }}
          >
            {t`Show more comments`}
          </Button>
        )}

        {messagesForDisplay.map((message) => {
          const authorUser = users[message.data.authorUserId];
          const authorName = message.data.isSupportTeam
            ? t`Support team`
            : authorUser?.fullName || authorUser?.email || "";

          const lastChangeTime = message.data.updatedAt || message.data.createdAt;
          const lastChangeAgo = lastChangeTime ? dayjs(lastChangeTime).fromNow() : "";

          const isMyMessage = message.data.authorUserId === auth.uid;
          const isMessageEditing = editMessageId === message.id;
          return (
            <CommentContainer
              key={message.id}
              style={{
                backgroundColor: isMessageEditing
                  ? COLORS["bg-card"]
                  : isNewMessage(message)
                    ? `rgba(0, 0, 0, 0.05)`
                    : "transparent",
                boxShadow: isMessageEditing ? `0 0 0 3px ${COLORS["primary"]}` : "none",
                borderRadius: "5px",
              }}
              onMouseOver={() => markMessageAsRead(message)}
            >
              <Stack sx={{ paddingTop: "5px", width: "30px" }}>
                <ColorTextAvatar
                  src={authorUser?.photoURL || ""}
                  sx={{ width: "30px", height: "30px" }}
                  name={authorName}
                />
              </Stack>

              <Stack key={message.id} sx={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  sx={{
                    opacity: 0.8,
                    display: "flex",
                    width: "100%",
                    gap: "0 5px",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <b style={{ opacity: 0.7 }}>{authorName}</b>

                  <span
                    style={{
                      display: "flex",
                      gap: "0 10px",
                      alignItems: "center",
                    }}
                  >
                    {message.data.isDraft && (
                      <Tooltip title={t`Press approve or reject to send this comment`}>
                        <Typography variant="caption" sx={{ color: COLORS["warning"] }}>
                          {t`Draft`}
                        </Typography>
                      </Tooltip>
                    )}
                    <Typography variant="caption" color={COLORS["text-secondary"]}>
                      {lastChangeAgo}
                    </Typography>
                    {isMyMessage && canComment && (
                      <IconButton
                        onClick={() => {
                          if (isMessageEditing) {
                            setEditMessageId(null);
                            setEditMessageIdToStorage(null);
                          } else {
                            editCommentInput(
                              richText ? message.data.richTextContent || "" : message.data.content,
                            );
                            setEditMessageId(message.id);
                            setEditMessageIdToStorage(message.id);
                          }
                        }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    )}

                    {deleteMessage && isMyMessage && (
                      <>
                        <IconButton onClick={() => deleteMessage(message.id)} size="small">
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </span>
                </Typography>

                {richText ? (
                  <RichTextViewer value={message.data.richTextContent || message.data.content} />
                ) : (
                  <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                    {message.data.content}
                  </Typography>
                )}

                <Typography variant="body2">
                  <small>
                    {message.data.ccUserIds.length > 0 && (
                      <>
                        <span> CC: </span>
                        {message.data.ccUserIds.map((ccUserId) => {
                          const ccUser = users[ccUserId];
                          const ccUserName = ccUser?.fullName || ccUser?.email || "";
                          return <span key={ccUserId}>{ccUserName}, </span>;
                        })}
                      </>
                    )}
                  </small>
                </Typography>
                <Stack gap="5px">
                  {message.data.attachments?.map((file, index) => (
                    <UploadedFile
                      key={index}
                      fileInfo={{ ...file, fullFileName: file.name }}
                      onClick={() => {
                        const isImage = isImageName(file.name + "." + file.extension);
                        if (isImage) return;

                        window.open(file.url, "_blank");
                      }}
                    />
                  ))}
                </Stack>
              </Stack>
            </CommentContainer>
          );
        })}

        {isEmpty && (
          <Typography
            variant="body2"
            sx={{
              color: COLORS["text-label"],
            }}
          >
            {emptyMessage || t`No comments yet`}
          </Typography>
        )}

        {canComment && (
          <>
            {showCC && (
              <Box sx={{ width: "calc(100% - 50px)" }}>
                <MultiValueInput
                  selectedElements={ccUsers}
                  allElements={usersOptions}
                  onChange={(selectedElements) => setCcUsers(selectedElements)}
                  label={t`Mention users`}
                  placeholder=""
                />
              </Box>
            )}

            <SendMessageForm>
              {richText ? (
                <RichTextEditor
                  value={commentInput}
                  onChange={(commentInput) => editCommentInput(commentInput)}
                  placeholder={placeholder || t`Add a comment`}
                  limitedToolbar
                />
              ) : (
                <TextAreaInput
                  label=""
                  rows={3}
                  placeholder={placeholder || t`Add a comment`}
                  value={commentInput}
                  onChange={(commentInput: string) => editCommentInput(commentInput)}
                />
              )}

              <Stack>
                <IconButton
                  onClick={() => {
                    if (commentInput) {
                      createMessageHandler("");
                    }
                  }}
                >
                  <SendIcon />
                </IconButton>
                {editMessageId && (
                  <IconButton
                    onClick={() => {
                      setEditMessageId(null);
                      editCommentInput("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Stack>
            </SendMessageForm>

            {canAttachFiles && (
              <Stack gap="10px">
                {isUploading && (
                  <Typography variant="caption" sx={{ color: COLORS["primary"] }}>
                    {t`Uploading files...`}
                  </Typography>
                )}

                {commentAttachments.length > 0 && (
                  <Stack gap="5px">
                    {commentAttachments.map((file, index) => (
                      <UploadedFile
                        key={index}
                        fileInfo={{ ...file, fullFileName: file.name }}
                        onDelete={onDeleteFile}
                        onClick={() => {
                          const isImage = isImageName(file.name + "." + file.extension);
                          if (isImage) return;
                          window.open(file.url, "_blank");
                        }}
                      />
                    ))}
                  </Stack>
                )}

                <Stack
                  sx={{
                    width: "calc(100% - 50px)",
                  }}
                >
                  <FileUploadButton
                    availableExtensions={[]}
                    maxFileSize={MB * 10}
                    size="small"
                    multiple
                    onUploadMultiple={async (files) => uploadBunch(files)}
                    title={t`Attach files to the comment`}
                  />
                </Stack>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </>
  );
};
