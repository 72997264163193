import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  InsertImage,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  UndoRedo,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "src/core/Auth/useAuth";
import { uploadFile } from "src/core/Backend/uploadFile";
import { companyName } from "src/core/Url/url";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Box, InputLabel, Stack } from "@mui/material";

import { Loader } from "../Loader/Loader";
import { EditorContainer } from "./style";

interface RichTextEditorProps {
  value: string;
  label?: string;
  onChange: (newValue: string) => void;
  required?: boolean;
  placeholder?: string;
  tooltipContent?: React.ReactNode;
  limitedToolbar?: boolean;
  id?: string;
  maxHeight?: string;
  allowImages?: boolean;
}

export default function RichTextEditor({
  value,
  onChange,
  label,
  required,
  placeholder,
  tooltipContent,
  limitedToolbar,
  id,
  maxHeight,
  allowImages = true,
}: RichTextEditorProps) {
  const ref = useRef<MDXEditorMethods>(null);
  const container = useRef<HTMLDivElement>(null);
  const auth = useAuth();
  const userId = auth.uid;
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.setMarkdown(value);
    }
  }, [value]);

  useEffect(() => {
    if (!id || !container.current) {
      return;
    }

    const element = container.current;
    const input = element.querySelector("[role=textbox]");
    if (input) {
      input.id = id;
    }
  }, [id]);

  const imageUploadHandler = async (image: File) => {
    if (!image) {
      return "";
    }

    setIsUploading(true);
    const fileName = image?.name || "";

    try {
      const userPhotoUrl = await uploadFile(
        `${companyName}/richTextEditor/${userId}/files/${fileName}`,
        image,
      );

      return userPhotoUrl;
    } catch (error) {
      console.error("Error uploading image", error);
      alert("Error uploading image");
      return "";
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Stack gap="5px">
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Box
        display="flex"
        alignItems="flex-start"
        sx={{
          position: "relative",
          gap: "10px",
          ":focus-within": {
            boxShadow: `0 0 0 1px ${COLORS["bg-card"]},0 0 0 3px ${COLORS["primary"]}`,
            borderRadius: "6px",
          },
        }}
      >
        <EditorContainer limited={!tooltipContent} maxHeight={maxHeight}>
          {isUploading && (
            <Stack
              sx={{
                position: "absolute",
                top: "calc(50% - 40px)",
                left: "calc(50% - 40px)",
                zIndex: 1,
              }}
            >
              <Loader />
            </Stack>
          )}
          <Stack
            sx={{
              backgroundColor: isUploading ? COLORS["text-secondary"] : "white",
              borderRadius: "6px",
              border: "1px solid #d1d1d1",
              opacity: isUploading ? 0.3 : 1,
            }}
            ref={container}
          >
            <MDXEditor
              ref={ref}
              className="rich-editor"
              contentEditableClassName="rich-editor-content"
              markdown={value || ""}
              onChange={(newValue) => onChange(newValue)}
              placeholder={placeholder || "Type here"}
              plugins={[
                headingsPlugin(),
                listsPlugin(),
                quotePlugin(),
                thematicBreakPlugin(),
                linkPlugin(),
                linkDialogPlugin(),
                imagePlugin({
                  imageUploadHandler: allowImages ? imageUploadHandler : undefined,
                }),
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      <BoldItalicUnderlineToggles />
                      {!limitedToolbar && <BlockTypeSelect />}
                      <ListsToggle options={["bullet", "number"]} />
                      {!limitedToolbar && <UndoRedo />}
                      <CreateLink />
                      {allowImages && <InsertImage />}
                    </>
                  ),
                }),
              ]}
            />
          </Stack>
        </EditorContainer>
        {tooltipContent && <Box>{tooltipContent}</Box>}
      </Box>
    </Stack>
  );
}
