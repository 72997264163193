import { t } from "@lingui/macro";
import { Trash } from "iconsax-react";
import React, { FC } from "react";

import { Box, Button, Checkbox, IconButton, Stack, TextField, Tooltip } from "@mui/material";

import { CategoryInfo } from "@common/vacancy.types";

import { categoriesColorsOptions } from "../Vacancy/data/defaultCategories";

interface CategoriesListProps {
  categories: CategoryInfo[];
  candidateCategoriesIds: string[];
  onSelectCategory: (categoryId: string, selected: boolean) => void;
  onRemoveCategory: (categoryId: string) => void;
  onChangeCategoryColor: (categoryId: string, color: string) => void;
  onChangeCategoryValue: (categoryId: string, value: string) => void;
  isAbleToSelectCategory?: boolean;
}

export const CategoriesList: FC<CategoriesListProps> = ({
  categories,
  candidateCategoriesIds,
  onSelectCategory,
  onRemoveCategory,
  onChangeCategoryColor,
  onChangeCategoryValue,
  isAbleToSelectCategory,
}) => {
  return (
    <>
      {categories.map((category, index) => (
        <Stack
          key={category.id}
          direction="row"
          sx={{
            gap: "10px",
            alignItems: "center",
          }}
        >
          {isAbleToSelectCategory && (
            <Checkbox
              checked={candidateCategoriesIds.includes(category.id)}
              onChange={(e) => onSelectCategory(category.id, e.target.checked)}
            />
          )}

          <Box
            sx={{
              position: "relative",
              zIndex: 11,
              backgroundColor: category.color,
              boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.5)",
              height: "42px",
              width: "42px",
              padding: "0",
              marginRight: "0px",
              borderRadius: "3px",
              ":focus": {
                ".color-picker": {
                  display: "flex",
                },
              },
            }}
            role="button"
            tabIndex={0}
          >
            <Stack
              direction="row"
              className="color-picker"
              sx={{
                display: "none",
                position: "absolute",
                top: "-3px",
                left: "55px",
                backgroundColor: "#fff",
                width: "155px",
                flexWrap: "wrap",
                gap: "5px",
                padding: "10px",
                boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.2), 0px 0px 0 1px rgba(0, 0, 0, 0.2)",
                borderRadius: "5px",
                zIndex: 122222,
              }}
            >
              {categoriesColorsOptions.map((color) => (
                <Button
                  sx={{
                    backgroundColor: color,
                    height: "35px",
                    width: "35px",
                    padding: "0",
                    border: "1px solid #fff",
                    ":hover": {
                      opacity: 0.8,
                      backgroundColor: color,
                    },
                  }}
                  key={color}
                  onMouseDown={() => onChangeCategoryColor(category.id, color)}
                />
              ))}
            </Stack>
          </Box>

          <TextField
            label=""
            value={category.value}
            sx={{
              width: "320px",
            }}
            onChange={(e) => onChangeCategoryValue(category.id, e.target.value)}
            placeholder={t`Tag ${index + 1}`}
          />
          <Tooltip title={t`Remove tag from all cards`}>
            <IconButton onClick={() => onRemoveCategory(category.id)}>
              <Trash size={18} />
            </IconButton>
          </Tooltip>
        </Stack>
      ))}
    </>
  );
};
