import { lazy } from "react";
import { Route } from "react-router-dom";

const DocumentListPage = lazy(() => import("./DocumentListPage/DocumentListPage"));
const DocumentEditorPage = lazy(() => import("./DocumentEditorPage/DocumentEditorPage"));

export const documentListRouter = (
  <>
    <Route path="/documents" element={<DocumentListPage />} />
    <Route path="/document-editor" element={<DocumentEditorPage />} />
    <Route path="/document-editor/:documentId" element={<DocumentEditorPage />} />
  </>
);
