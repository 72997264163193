import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import { Components, ComponentsOverrides, ComponentsVariants } from "@mui/material";

import { typographySmaller } from "../Typography/style";
import { COLORS } from "../assets/styles/colors";

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    iconSquareSmall: true;
    iconSquareLarge: true;
  }
}

export const buttonBorderRadius = "12px";

const variants: ComponentsVariants["MuiButton"] = [
  {
    props: { size: "iconSquareSmall" },
    style: {
      paddingInlineEnd: "14px",
      paddingInlineStart: "14px",
    },
  },
  {
    props: { size: "iconSquareLarge" },
    style: {
      height: "3.4rem",
      paddingInlineEnd: "18px",
      paddingInlineStart: "18px",
    },
  },
  {
    props: { variant: "outlined" },
    style: {
      "&:focus": {
        border: `1px solid ${COLORS["primary"]}`,
        boxShadow: `0px 0px 0px 2px ${COLORS["text-contrast"]}, 0px 0px 0px 4px ${COLORS["primary"]}`,
      },
    },
  },

  {
    props: { variant: "contained" },
    style: {
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },

  {
    props: { size: "large" },
    style: {
      height: "3.4rem",
      paddingInlineEnd: "24px",
      paddingInlineStart: "24px",
    },
  },
  {
    props: { variant: "text" },
    style: {
      "&:focus": {
        background: COLORS["bg-row"],
        border: "none",
        boxShadow: "none",
      },
    },
  },
];

const style: ComponentsOverrides["MuiButton"] = {
  contained: {
    backgroundColor: COLORS["primary"],
  },
  outlined: {
    backgroundColor: COLORS["text-contrast"],
    boxShadow: "none",
    borderColor: COLORS["primary"],
    color: COLORS["primary"],
    "&:hover": {
      backgroundColor: COLORS["bg-row"],
    },
    "&:active": {
      backgroundColor: COLORS["bg-row"],
      boxShadow: "none",
    },
    "&:disabled": {
      color: COLORS["text-disabled"],
      backgroundColor: COLORS["text-contrast"],
    },
  },
  text: {
    "&:disabled": {
      background: "none",
      color: COLORS["text-disabled"],
    },
  },
  root: {
    borderRadius: buttonBorderRadius,
    height: "3rem",
    paddingInlineEnd: "16px",
    paddingInlineStart: "16px",
    padding: "12px 16px",
    minWidth: 0,
    lineHeight: "1rem",
    "@media (max-width: 600px)": {
      height: "2.5rem",
      lineHeight: "0.8rem",
    },
    "& .MuiButton-startIcon": {
      marginRight: "7px",
    },
    fontStyle: "normal",
    ...typographySmaller,
    textTransform: "none",
    fontFamily: "var(--font-family)",
    "& .MuiSvgIcon-root": {
      height: "1.5rem",
      width: "1.5rem",
      margin: 0,
      paddingInlineStart: 0,
    },
    "&:active": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: `0 0 0 2px ${COLORS["text-contrast"]}, 0 0 0 4px ${COLORS["primary"]}`,
    },
    "&:disabled": {
      color: COLORS["text-contrast"],
      backgroundColor: COLORS["text-disabled"],
    },
  },
};

export const customButton: GetTypeOfProperty<Components, "MuiButton"> = {
  variants: variants,
  styleOverrides: style,
};
