import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import { Components, ComponentsOverrides } from "@mui/material";

import { COLORS } from "../assets/styles/colors";

const style: ComponentsOverrides["MuiFormHelperText"] = {
  root: {
    fontSize: "0.875rem",
    marginLeft: "4px",
    fontFamily: "var(--font-family)",
    color: COLORS["text-label"],
    "&.Mui-error": {
      color: COLORS["error"],
    },
  },
};

export const customHelperText: GetTypeOfProperty<Components, "MuiFormHelperText"> = {
  styleOverrides: style,
};
