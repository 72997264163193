import { Context, createContext } from "react";

import { ClientCompany } from "@common/clientCompany.types";

export interface ClientCompaniesContext {
  clientCompanies: Record<string, ClientCompany | undefined>;
  clientCompaniesList: { data: ClientCompany; id: string }[];
}

export const clientCompaniesContext: Context<ClientCompaniesContext> =
  createContext<ClientCompaniesContext>({
    clientCompanies: {},
    clientCompaniesList: [],
  });
