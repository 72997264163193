import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import { Components } from "@mui/material";

import { COLORS } from "../assets/styles/colors";

export const customCircularProgress: GetTypeOfProperty<Components, "MuiCircularProgress"> = {
  defaultProps: { disableShrink: true },
};

const Rotate = keyframes({
  from: {
    transform: `rotate(0deg) `,
  },
  to: {
    transform: `rotate(360deg) `,
  },
});

export const AnimatedLoadingPanelComponent = styled.div({}, () => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "100px",
    color: COLORS["text-label"],
    position: "relative",
    padding: "0px 0 50px 0",
    p: {
      maxWidth: "400px",
      textAlign: "center",
    },
    svg: {
      width: "350px",
      marginLeft: "-70px",
    },
    opacity: "0.9",
  };
});

export const RotatedComponent = styled.div({}, () => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "300px",
    height: "300px",
    gap: "40px",
    color: COLORS["text-label"],
    position: "absolute",
    top: "0",
    opacity: "0.5",
    transform: "scale(2.5)",

    animation: `${Rotate} 33s linear infinite`,
  };
});
