import styled from "@emotion/styled";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Typography } from "@mui/material";

import { buttonBorderRadius } from "../Button/style";
import { COLORS } from "../assets/styles/colors";

export const FileButton = styled.button<{ size?: "small" | "medium" | "large" }>(
  {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 20px",

    width: "100%",
    background: "transparent",
    border: `1px solid ${COLORS["primary"]}`,
    borderRadius: buttonBorderRadius,
    boxSizing: "border-box",
    cursor: "pointer",
    ":focus-visible": {
      outline: `${COLORS["primary"]} auto 1px`,
    },
    ":disabled": {
      cursor: "default",
      opacity: 0.5,
    },
  },
  ({ size }) => ({
    padding: size === "small" ? "5px 10px" : size === "medium" ? "7px 20px" : "16px 20px",
    gap: size === "small" ? "8px" : "16px",
  }),
);

export const Icon = styled(CloudUploadIcon)({
  fontSize: "2rem",
  color: COLORS["primary"],
});

export const UploadTextContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
});

export const UploadTextUnderline = styled(Typography)({
  textDecorationLine: "underline",
  color: COLORS["link"],
});

export const UploadTextInfo = styled(Typography)({
  color: COLORS["text-secondary"],
  textAlign: "left",
});

export const DragAndDropContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px 20px",
  gap: "16px",
  backgroundColor: `rgba(255,255,255,0.8 )`,
  border: `5px dashed ${COLORS["border"]}`,
  borderRadius: "4px",
  boxSizing: "border-box",
  position: "fixed",
  width: "100%",
  height: "100%",
  zIndex: 1000,
  top: "0",
  left: "0",
});
