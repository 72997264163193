import { t } from "@lingui/macro";

import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Box, Chip, TextField, Tooltip, createFilterOptions } from "@mui/material";

import { MultiValueOption, OptionsInfoIcon } from "./style";

export interface MultiValueOption {
  title: string;
  value: string;
  description: string;
}
const filter = createFilterOptions<MultiValueOption>();

interface MultiValueInputProps {
  selectedElements: MultiValueOption[];
  allElements: MultiValueOption[];
  label?: string;
  placeholder?: string;
  onChange?: (selectedElements: MultiValueOption[]) => void;
  ableToCreateNew?: boolean;
  "data-test-id"?: string;
  disabled?: boolean;
}

export const MultiValueInput = ({
  selectedElements,
  allElements,
  onChange,
  label,
  placeholder,
  ableToCreateNew,
  disabled,
  ...options
}: MultiValueInputProps) => {
  const availableOptions = ableToCreateNew ? [...allElements, ...selectedElements] : allElements;

  return (
    <Autocomplete
      multiple
      {...options}
      value={selectedElements}
      options={availableOptions}
      onChange={(event, newSelectedElements) => onChange?.([...newSelectedElements])}
      getOptionLabel={(option) => option.title}
      filterSelectedOptions
      disabled={disabled}
      noOptionsText={t`No options`}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        const isExisting = options.some((option) => inputValue === option.value);
        if (ableToCreateNew && inputValue !== "" && !isExisting) {
          filtered.push({
            value: inputValue,
            title: t`Add "${inputValue}"`,
            description: inputValue,
          });
        }

        return filtered;
      }}
      isOptionEqualToValue={(elementA, elementB) => elementA.value === elementB.value}
      disableCloseOnSelect
      renderTags={(value: MultiValueOption[], getTagProps) =>
        value.map((option: MultiValueOption, index: number) => {
          const tagProps = getTagProps({ index });
          return (
            <Tooltip title={option.description} key={tagProps.key} arrow>
              <Chip
                variant="filled"
                size="medium"
                deleteIcon={<CloseIcon />}
                label={option.title}
                {...tagProps}
              />
            </Tooltip>
          );
        })
      }
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
      renderOption={(props, option) => {
        return (
          <Box component={"li"} {...props}>
            <MultiValueOption>
              {option.title}
              <Tooltip title={option.description} arrow>
                <OptionsInfoIcon />
              </Tooltip>
            </MultiValueOption>
          </Box>
        );
      }}
    />
  );
};
