import { Modal } from "@mui/material";

import { ModalFullBox } from "../ConfirmModal/style";

interface ImagePreviewProps {
  images: string[];
  onClose: () => void;
}

export const ImagePreview = ({ images, onClose }: ImagePreviewProps) => {
  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalFullBox
        onClick={onClose}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100vw",
          height: "100vh",
        }}
      >
        <img
          src={images[0]}
          alt="preview"
          style={{
            maxWidth: "94vw",
            maxHeight: "94vh",
          }}
        />
      </ModalFullBox>
    </Modal>
  );
};
