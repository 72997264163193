import { SnapshotOptions } from "@firebase/firestore-types";
import FirebaseFirestore from "@google-cloud/firestore";
import { collection, doc } from "firebase/firestore";

import { ClientCompany } from "@common/clientCompany.types";
import { CompanyFeaturesSettings, CompanyInfo } from "@common/company.types";
import { CVDocument, CVDocumentBackup, CVPreview, CvGlobalStat } from "@common/cv.types";
import { CoreProjectSettings, DepartmentInfo } from "@common/db.types";
import { Document } from "@common/document.types";
import { HiringPipeline } from "@common/hiringPipeline.types";
import { Message } from "@common/message.types";
import { Portfolio } from "@common/portfolio.types";
import { SharedLink } from "@common/sharedLinks.types";
import { ShowcaseData } from "@common/showcase.types";
import { SupportRequest } from "@common/support.types";
import { SystemRole } from "@common/systemRole.types";
import { Bill, CustomerDocument, UsageAiRecord } from "@common/usage.types";
import { UserInfo, UserSettings } from "@common/user.types";
import {
  Vacancy,
  VacancyBackup,
  VacancyCandidate,
  VacancyCandidateBackup,
  VacancyStat,
} from "@common/vacancy.types";

import { companyName } from "../Url/url";
import { firestore } from "./index";

interface FirestoreDataConverter<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toFirestore(model: T): any;

  fromFirestore(snapshot: unknown, options?: SnapshotOptions): T;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const converter = <T>() => ({
  toFirestore: (data: Partial<T>) => data,
  fromFirestore: (snap: FirebaseFirestore.QueryDocumentSnapshot) => snap.data() as T,
});

export const dataPointCollection = <T>(collectionPath: string) =>
  // eslint-disable-next-line
  collection(firestore, collectionPath).withConverter(converter<T>() as FirestoreDataConverter<T>);

export const dataPointDoc = <T>(documentPath: string) =>
  // eslint-disable-next-line
  doc(firestore, documentPath).withConverter(converter<T>() as FirestoreDataConverter<T>);
const db = {
  collections: {
    users: dataPointCollection<UserInfo>(`companies/${companyName}/users`),
    systemRoles: dataPointCollection<SystemRole>(`companies/${companyName}/systemRoles`),
    showcases: dataPointCollection<ShowcaseData>(`companies/${companyName}/showcases`),
    portfolios: dataPointCollection<Portfolio>(`companies/${companyName}/portfolios`),
    cv: dataPointCollection<CVDocument>(`companies/${companyName}/cv`),
    cvBackup: dataPointCollection<CVDocumentBackup>(`companies/${companyName}/cvBackup`),
    cvPreview: dataPointCollection<CVPreview>(`companies/${companyName}/cvPreview`),
    departments: dataPointCollection<DepartmentInfo>(`companies/${companyName}/departments`),
    vacancies: dataPointCollection<Vacancy>(`companies/${companyName}/vacancies`),
    vacanciesBackup: dataPointCollection<VacancyBackup>(`companies/${companyName}/vacanciesBackup`),
    hiringPipeline: dataPointCollection<HiringPipeline>(`companies/${companyName}/hiringCircle`),
    vacancyCandidates: dataPointCollection<VacancyCandidate>(
      `companies/${companyName}/vacancyCandidates`,
    ),
    vacancyCandidatesBackup: dataPointCollection<VacancyCandidateBackup>(
      `companies/${companyName}/vacancyCandidatesBackup`,
    ),
    vacancyStat: dataPointCollection<VacancyStat>(`companies/${companyName}/vacancyStat`),
    documents: dataPointCollection<Document>(`companies/${companyName}/documents`),
    support: dataPointCollection<SupportRequest>(`companies/${companyName}/support`),
    messages: dataPointCollection<Message>(`companies/${companyName}/messages`),
    sharedLinks: dataPointCollection<SharedLink>(`companies/${companyName}/sharedLinks`),
    clientCompanies: dataPointCollection<ClientCompany>(`companies/${companyName}/clientCompanies`),
    usage: dataPointCollection<UsageAiRecord>(`companies/${companyName}/usage`),
    bills: dataPointCollection<Bill>(`companies/${companyName}/bills`),
  },
  documents: {
    config: {
      companyInfo: dataPointDoc<CompanyInfo>(`companies/${companyName}/config/companyInfo`),
      featuresSettings: dataPointDoc<CompanyFeaturesSettings>(
        `companies/${companyName}/config/featuresSettings`,
      ),
      paymentsInfo: dataPointDoc<CustomerDocument>(`companies/${companyName}/config/paymentsInfo`),
      coreSettings: dataPointDoc<CoreProjectSettings>(`settings/core`),
    },
    cvStats: dataPointDoc<CvGlobalStat>(`companies/${companyName}/stats/cv`),
    usageStats: dataPointDoc<{ [key: string]: number }>(
      `companies/${companyName}/usageStats/monthlyStat`,
    ),
  },
  getUserSettingsDataPoint(userId: string) {
    return dataPointDoc<UserSettings>(
      `companies/${companyName}/users/${userId}/privateInfo/settings`,
    );
  },
  getUserInfoDataPoint(userId: string) {
    return dataPointDoc<UserInfo>(`companies/${companyName}/users/${userId}`);
  },
};

export default db;
