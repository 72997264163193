import { FC } from "react";
import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Modal, Stack } from "@mui/material";

import { CloseButton, ModalContainer } from "./style";

interface CustomModalProps {
  onClose: () => void;
  children: React.ReactNode;
  width?: string;
}

export const CustomModal: FC<CustomModalProps> = ({ onClose, width, children }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <ModalContainer width={width || "min(600px, 90vw)"}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Stack
          gap="30px"
          sx={{
            height: "100%",
          }}
        >
          {children}
        </Stack>
      </ModalContainer>
    </Modal>
  );
};
