import styled from "@emotion/styled";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Typography } from "@mui/material";

export const UserTextInfo = styled(Typography)({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  color: COLORS["text-secondary"],
});
