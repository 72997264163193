import React from "react";
import { lazy } from "react";
import { Route } from "react-router-dom";

const GlobalAnalyticsPage = lazy(() => import("./GlobalAnalyticsPage"));

export const globalAnalyticsRouter = (
  <>
    <Route path="/global-analytics" element={<GlobalAnalyticsPage />} />
  </>
);
