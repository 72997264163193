import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import db from "src/core/Backend/firestore";
import { defaultUsageLimit } from "src/core/Usage/UsageDashBoard";
import { calculatePriceForUsage } from "src/core/Usage/data/price";
import { DAY } from "src/core/Utils/time";

import { UsageContext } from "./usageContext";

export function useProviderUsage(): UsageContext {
  const [usageDocs] = useCollection(db.collections.usage);

  const currentMonth = new Date().getMonth();
  const [pageMonth] = useState<number>(currentMonth);

  const firstMonthDayTimestamp = dayjs().month(pageMonth).startOf("month").valueOf();
  const lastMonthDayTimestamp = dayjs().month(pageMonth).endOf("month").valueOf();
  const [bills] = useCollection(db.collections.bills);
  const unpaidBills =
    bills?.docs
      .sort((a, b) => {
        const aDate = a.data().endTimestamp;
        const bDate = b.data().endTimestamp;
        return bDate - aDate;
      })
      .filter((bill) => !bill.data().isPaid) || [];

  const lastUnpaidBill = unpaidBills[0];

  const usageList = useMemo(() => usageDocs?.docs.map((doc) => doc.data()) || [], [usageDocs]);
  const thisMonthUsage = useMemo(
    () =>
      usageList.filter(
        (usage) =>
          usage.createdAt >= firstMonthDayTimestamp && usage.createdAt <= lastMonthDayTimestamp,
      ),
    [usageList, firstMonthDayTimestamp],
  );
  const [companyInfo] = useDocumentData(db.documents.config.companyInfo);

  const limit = companyInfo?.usageLimit || defaultUsageLimit;

  const thisMonthPrice = thisMonthUsage.reduce((acc, usage) => {
    const price = calculatePriceForUsage(usage);
    return acc + price;
  }, 0);

  const isLimitReached = thisMonthPrice >= limit;

  const limitReachedMessage = `You have reached the usage limit. Please increase the limit to use all features.`;
  const unpaidBillMessage = `Your account has an unpaid bill. Please pay it to avoid losing access to some features.`;
  const isBillUnpaid = lastUnpaidBill && !lastUnpaidBill.data().isPaid;

  const unpaidBillCreated = lastUnpaidBill?.data().createdAt || 0;
  const warningUnpaidBillDuration = 7 * DAY;
  const now = Date.now();
  const isBillUnpaidWarning = unpaidBillCreated + warningUnpaidBillDuration > now;
  return {
    isWarning: isBillUnpaid && isBillUnpaidWarning,
    loading: false,
    isLimited: isLimitReached || (isBillUnpaid && !isBillUnpaidWarning),
    isLimitReached,
    isUnpaidBill: isBillUnpaid,
    limitedMessage: isBillUnpaid ? unpaidBillMessage : isLimitReached ? limitReachedMessage : "",
  };
}
