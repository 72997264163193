import { useCollection } from "react-firebase-hooks/firestore";
import db from "src/core/Backend/firestore";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";

import { Vacancy } from "@common/vacancy.types";

import { useAuth } from "../../../../core/Auth/useAuth";

interface VacancyDoc {
  id: string;
  data: Vacancy;
}

export const useVacancies = (): [VacancyDoc[], boolean] => {
  const auth = useAuth();
  const [vacancyList, loading] = useCollection(auth.uid ? db.collections.vacancies : null);
  const users = useUsers();

  const listOfVacancies =
    vacancyList?.docs
      .map((vacancy) => {
        return { data: vacancy.data(), id: vacancy.id };
      })
      .filter(({ data }) => {
        let clientsEmails =
          data.clientType === "Internal"
            ? data.responsibleInternalCustomersEmails || []
            : data.responsibleCustomersEmails;

        clientsEmails = clientsEmails.map((email) => email.toLowerCase());

        return (
          !users.isClientForHiring ||
          (auth.userInfo.email && clientsEmails.includes(auth.userInfo.email.toLowerCase()))
        );
      })
      .sort((a, b) => {
        return a.data.createdAt > b.data.createdAt ? -1 : 1;
      }) || [];

  return [listOfVacancies, loading];
};
