import { Context, createContext } from "react";

import { CompanyFeaturesSettings } from "@common/company.types";

export interface FeaturesSettingsContext {
  featuresSettings: CompanyFeaturesSettings;
  featuresSettingsLoading: boolean;
  saveSettings: (newData: Partial<CompanyFeaturesSettings>) => void;
}

export const featuresSettingsContext: Context<FeaturesSettingsContext> =
  createContext<FeaturesSettingsContext>({
    featuresSettings: {
      recruitmentBase: true,
      portfolioBranding: true,
      portfolioBase: true,
      portfolioFeedback: true,
    },
    featuresSettingsLoading: false,
    saveSettings: () => {
      throw new Error("Function saveSettings in securitySettings hook is not implemented");
    },
  });
