import styled from "@emotion/styled";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { IconButton } from "@mui/material";

export const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "10px",
  right: "10px",
});

export const ModalContainer = styled.div<{
  width: string;
  padding?: string;
}>({}, ({ width, padding }) => ({
  backgroundColor: COLORS["bg-card"],
  padding: padding || "25px 30px 25px 30px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: width || "min(90vw)",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  boxSizing: "border-box",

  maxHeight: "calc(100vh)",
  overflowY: "auto",
  borderRadius: "5px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
  "@media (max-width: 500px)": {
    padding: "40px 20px 10px 20px",
  },
}));
