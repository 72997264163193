import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import { Components, ComponentsOverrides } from "@mui/material";

const style: ComponentsOverrides["MuiTextField"] = {
  root: {
    fontFamily: "var(--font-family)",
    ".Mui-readOnly": {
      backgroundColor: "transparent",
    },
  },
};

export const customTextField: GetTypeOfProperty<Components, "MuiTextField"> = {
  styleOverrides: style,
};
