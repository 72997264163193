import { lazy } from "react";
import { Route } from "react-router-dom";

const SecuritySettings = lazy(() => import("./SecuritySettings"));

export const securitySettingsRouter = (
  <>
    <Route path="/settings/security" element={<SecuritySettings />} />
  </>
);
