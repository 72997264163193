import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { useUserSettings } from "src/core/Users/hooks/useUserSettings";
import { useUsers } from "src/core/Users/hooks/useUsers/useUsers";
import { AutoSaveIndicator } from "src/uiKit/AutoSaveIndicator/AutoSaveIndicator";
import { COLORS } from "src/uiKit/assets/styles/colors";

import DoneIcon from "@mui/icons-material/Done";
import {
  Button,
  FormLabel,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import {
  MessageNotification,
  RemindersNotification,
  VacancyApprovalNotification,
} from "@common/user.types";

import { useAuth } from "../Auth/useAuth";

interface NotificationsSettingsFormProps {
  onClose: () => void;
}

export const NotificationsSettingsForm = ({
  onClose,
}: NotificationsSettingsFormProps): JSX.Element => {
  const { user } = useAuth();
  const userSettings = useUserSettings();
  const [isSaving, setIsSaving] = useState(false);
  const users = useUsers();

  useEffect(() => {
    setTimeout(() => setIsSaving(false), 300);
  }, [isSaving]);

  if (!user || userSettings.loading) {
    return <></>;
  }

  const messagesOptions: MessageNotification[] = ["Instantly", "Daily", "Weekly", "Off"];
  const messagesOptionsLabel: Record<MessageNotification, string> = {
    Instantly: t`Instantly`,
    Daily: t`Daily`,
    Weekly: t`Weekly`,
    Off: t`Off`,
  };

  const remindersOptions: RemindersNotification[] = ["On", "Off"];
  const remindersOptionsLabel: Record<RemindersNotification, string> = {
    On: t`On`,
    Off: t`Off`,
  };

  const vacancyApprovalOptions: VacancyApprovalNotification[] = ["On", "Off"];
  const vacancyApprovalOptionsLabel: Record<VacancyApprovalNotification, string> = {
    On: t`On`,
    Off: t`Off`,
  };

  return (
    <form
      action=""
      onSubmit={(e) => {
        e.preventDefault();
        onClose();
      }}
    >
      <Stack>
        <Stack gap={"25px"}>
          <Stack>
            <Typography variant="h4">{t`Notifications`}</Typography>
            <Typography
              variant="caption"
              sx={{
                color: COLORS["text-secondary"],
              }}
            >{t`Change your email notifications`}</Typography>
          </Stack>

          <Stack sx={{ gap: "15px" }}>
            <Stack>
              <FormLabel htmlFor="messages">{t`Comments:`}</FormLabel>

              <ToggleButtonGroup
                color="primary"
                id="messages"
                size="small"
                exclusive
                sx={{
                  flexWrap: "wrap",
                }}
                value={userSettings.emailMessages || "Instantly"}
                onChange={async (e, newValue) => {
                  if (!newValue) return;
                  setIsSaving(true);
                  await userSettings.updateUserSettings({
                    emailMessages: newValue as MessageNotification,
                  });
                  setIsSaving(false);
                }}
              >
                {messagesOptions.map((message) => (
                  <ToggleButton key={message} value={message}>
                    {messagesOptionsLabel[message]}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>

            {!users.isClientForHiring && (
              <>
                <Stack>
                  <FormLabel htmlFor="messages">{t`Candidate Reminders:`}</FormLabel>

                  <ToggleButtonGroup
                    color="primary"
                    id="messages"
                    exclusive
                    size="small"
                    value={userSettings.reminders || "On"}
                    onChange={async (e, newValue) => {
                      if (!newValue) return;
                      setIsSaving(true);
                      await userSettings.updateUserSettings({
                        reminders: newValue as RemindersNotification,
                      });
                      setIsSaving(false);
                    }}
                  >
                    {remindersOptions.map((message) => (
                      <ToggleButton key={message} value={message}>
                        {remindersOptionsLabel[message]}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
                <Stack>
                  <FormLabel htmlFor="messages">{t`Vacancy Approval:`}</FormLabel>

                  <ToggleButtonGroup
                    color="primary"
                    id="messages"
                    exclusive
                    size="small"
                    value={userSettings.vacancyApproval || "On"}
                    onChange={async (e, newValue) => {
                      if (!newValue) return;
                      setIsSaving(true);
                      await userSettings.updateUserSettings({
                        vacancyApproval: newValue as VacancyApprovalNotification,
                      });
                      setIsSaving(false);
                    }}
                  >
                    {vacancyApprovalOptions.map((message) => (
                      <ToggleButton key={message} value={message}>
                        {vacancyApprovalOptionsLabel[message]}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
              </>
            )}
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AutoSaveIndicator isSaving={isSaving} />
            <Button startIcon={<DoneIcon />} variant="contained" type="submit">
              {t`Done`}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
