import styled from "@emotion/styled";

import { COLORS } from "../assets/styles/colors";

export const CookiesBarContainer = styled.div({
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100%",
  padding: "6px max(calc(50vw - 700px), 50px)",
  color: COLORS["text-contrast"],
  backgroundColor: COLORS["secondary"],
  display: "flex",
  flexWrap: "wrap",
  boxSizing: "border-box",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  zIndex: 99999,

  p: {
    width: "60rem",
    maxWidth: "max(65vw, 700px)",
    fontSize: "0.8rem",
    margin: 0,
  },
  a: {
    color: COLORS["text-contrast"],
    margin: "0 0.2rem",
  },
  ".action-button": {
    minWidth: "10rem",
    padding: "10px 40px !important",
    height: "auto",
  },
});
