interface SourceCategory {
  title: string;
  detailedTitle: string;
}

const sourceCategories: SourceCategory[] = [
  {
    title: "Social media platform",
    detailedTitle: "Specify the name of Social media platform",
  },
  {
    title: "Referral",
    detailedTitle: "Specify the name of the person who referred the candidate",
  },
  {
    title: "Community groups",
    detailedTitle: "Specify the name of Community groups",
  },
  {
    title: "Networking event",
    detailedTitle: "Specify the name of Networking event",
  },
  {
    title: "Work conference",
    detailedTitle: "Specify the name of Work conference",
  },
  {
    title: "Other",
    detailedTitle: "Specify where you have found the candidate",
  },
];

export const defaultSourceCategories = sourceCategories.map((category) => category.title);
