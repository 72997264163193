import { ComponentsOverrides } from "@mui/material";

const drawerStyleOverrides: ComponentsOverrides["MuiDrawer"] = {
  paper: {
    borderRadius: "0px", // Remove border-radius
  },
};

export const customDrawer = {
  styleOverrides: drawerStyleOverrides,
};
