import { t } from "@lingui/macro";
import { DirectInbox, HambergerMenu } from "iconsax-react";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appType } from "src/core/AppConfig/appConfig";
import ColorTextAvatar from "src/uiKit/Avatar/ColorTextAvatar";
import { MagicBackground } from "src/uiKit/Background/MagicBackground";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Badge, Drawer, ListItemIcon, ListItemText, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Feed from "../Feed/Feed";
import { TEST_SELECTOR, getTestDataIdAttribute } from "../Tests/selectors";
import { HeaderElements } from "./HeaderElements";
import { SearchHeaderIcon } from "./SearchIcon";
import { AppBarCustom, HeaderLogo, HeaderLogoLink, tabColor } from "./style";

type MenuType = "Button" | "EmptyButton" | "Tab" | "SubMenu" | "Divider" | "MenuRow";

export interface HeaderMenuElement {
  linkTo?: string;
  activeLinksTo?: string[];
  title?: string;
  child?: HeaderMenuElement[];
  handler?: () => void;
  type: MenuType;
  testDataId?: string;
  icon?: JSX.Element;
}
interface HeaderProps {
  leftHeaderMenuItems?: HeaderMenuElement[];
  userSettingsMenu?: HeaderMenuElement[];
  rightHeaderMenuItems?: HeaderMenuElement[];
  avatarURL?: string;
  viewBreakpointWidth: number;
  showNotifications?: boolean;
  notificationBadge?: string;
  showAppRouterButton?: boolean;
  userName?: string;
  userEmail?: string;
  showSearch?: boolean;
}

const BaseHeader: FC<HeaderProps> = ({
  leftHeaderMenuItems = [],
  userSettingsMenu = [],
  rightHeaderMenuItems = [],
  avatarURL,
  viewBreakpointWidth,
  showNotifications,
  notificationBadge,
  userName,
  userEmail,
  showSearch,
}) => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] = useState<null | HTMLElement>(null);
  const [anchorNotification, setAnchorNotification] = useState<null | HTMLElement>(null);

  const [hiddenMenu, setHiddenMenu] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth < viewBreakpointWidth) {
        setHiddenMenu(true);
      } else {
        setHiddenMenu(false);
      }
    };

    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [viewBreakpointWidth]);

  const closeMenus = () => {
    setAnchorElUser(null);
    setAnchorElSettings(null);
    setAnchorNotification(null);
  };

  const renderMainMenuForMobil = (items: HeaderMenuElement[], skipIcon?: boolean) => {
    const itemsForRender: HeaderMenuElement[] = [];
    items.forEach((menuItem) => {
      const isChild = menuItem.child && menuItem.child.length > 0;
      if (isChild) {
        itemsForRender.push({ type: "Divider" });
        itemsForRender.push(menuItem);
        menuItem.child?.forEach((item) => {
          itemsForRender.push(item);
        });
        itemsForRender.push({ type: "Divider" });
      } else {
        itemsForRender.push(menuItem);
      }
    });

    return itemsForRender.map((menuElement, index) => {
      if (menuElement.type === "Divider") {
        return <Divider key={Date.now() + Math.random()} />;
      }

      const isChild = menuElement.child && menuElement.child.length > 0;

      return (
        <MenuItem
          key={menuElement.title + `index${index}`}
          sx={{
            pointerEvents: isChild ? "none" : "inherit",
          }}
          onClick={(e) => {
            e.preventDefault();
            if (menuElement.handler) {
              menuElement.handler();
            } else if (menuElement.linkTo) {
              const isThirdPartyLink = menuElement.linkTo.includes("http");
              if (isThirdPartyLink) {
                window.open(menuElement.linkTo, "_blank");
              } else {
                navigate(menuElement.linkTo);
              }
            }
            closeMenus();
          }}
        >
          {!skipIcon && <ListItemIcon>{menuElement.icon}</ListItemIcon>}
          <ListItemText>
            <Typography
              sx={{
                opacity: isChild ? 0.4 : 1,
                fontSize: isChild ? "0.7rem" : "inherit",
                color: tabColor,
              }}
            >
              {menuElement.title}
            </Typography>
          </ListItemText>
        </MenuItem>
      );
    });
  };

  const combinedMenu: HeaderMenuElement[] = [...leftHeaderMenuItems];

  if (rightHeaderMenuItems.length > 0) {
    if (leftHeaderMenuItems.length > 0) {
      combinedMenu.push({ type: "Divider" });
    }
    combinedMenu.push(...rightHeaderMenuItems);
  }

  return (
    <Stack
      sx={{
        width: "100%",
        boxSizing: "border-box",
        paddingBottom: "60px",
        "@media (max-width: 900px)": {
          paddingBottom: "0px",
        },
      }}
    >
      <AppBarCustom color="inherit">
        <HeaderLogoLink
          aria-label="Home"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
            navigate("/");
          }}
        >
          {appType === "profolio" && <HeaderLogo aria-hidden src="/logo.svg" alt="Home" />}
          {appType === "hiring" && (
            <HeaderLogo
              style={{ width: "112px", height: "31px" }}
              aria-hidden
              src="/logoHiring.svg"
              alt="Home"
            />
          )}
        </HeaderLogoLink>
        {leftHeaderMenuItems.length > 0 && (
          <HeaderElements menuItems={hiddenMenu ? [] : leftHeaderMenuItems} left />
        )}

        {rightHeaderMenuItems.length > 0 && (
          <HeaderElements menuItems={hiddenMenu ? [] : rightHeaderMenuItems} />
        )}

        {userSettingsMenu.length > 0 && hiddenMenu && (
          <Box>
            <Tooltip
              title="Open settings"
              {...getTestDataIdAttribute(TEST_SELECTOR.header.userProfile)}
            >
              <IconButton
                onClick={(e) => setAnchorElSettings(e.currentTarget)}
                sx={{ gap: "35px" }}
              >
                <HambergerMenu />
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: "35px" }}
              anchorEl={anchorElSettings}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElSettings)}
              onClose={() => setAnchorElSettings(null)}
            >
              {renderMainMenuForMobil(combinedMenu, true)}
            </Menu>
          </Box>
        )}

        {userSettingsMenu.length > 0 && showNotifications && showSearch && <SearchHeaderIcon />}

        {showNotifications && (
          <Box>
            <IconButton
              onClick={(e) => setAnchorNotification(e.currentTarget)}
              sx={{ margin: "0 3px" }}
            >
              <Badge badgeContent={notificationBadge ? notificationBadge : undefined} color="error">
                <DirectInbox size="22px" color={COLORS["text-label"]} />
              </Badge>
            </IconButton>
            <Drawer
              anchor={"right"}
              open={!!anchorNotification}
              onClose={() => setAnchorNotification(null)}
            >
              <Box sx={{ width: "min(95vw, 600px)", height: "2400px" }}>
                <Box sx={{ height: "max-content", minHeight: "101vh" }}>
                  <Feed onClose={() => setAnchorNotification(null)} />
                </Box>
              </Box>
            </Drawer>
          </Box>
        )}

        {userSettingsMenu.length > 0 && (
          <Box>
            <Tooltip
              title={t`Open settings`}
              {...getTestDataIdAttribute(TEST_SELECTOR.header.userProfile)}
            >
              <IconButton
                onClick={(e) => setAnchorElUser(e.currentTarget)}
                sx={{ margin: "0 3px" }}
              >
                <ColorTextAvatar
                  sx={{
                    fontSize: "1rem",
                  }}
                  name={userName || userEmail || ""}
                  src={avatarURL || ""}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              {renderMainMenuForMobil(userSettingsMenu, false)}
            </Menu>
          </Box>
        )}
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            overflow: "hidden",
            opacity: 0.95,
          }}
        >
          <MagicBackground />
        </Stack>
      </AppBarCustom>
      <Stack
        sx={{
          width: "100%",
          position: "absolute",
          top: 0,
          height: "100%",
          left: 0,
          right: 0,
          zIndex: 0,
        }}
      >
        <MagicBackground />
      </Stack>
    </Stack>
  );
};

export default BaseHeader;
