import { t } from "@lingui/macro";
import { FC, useState } from "react";
import { CustomModal } from "src/uiKit/Modal/CustomModal";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Button, InputAdornment, Link, Stack, TextField, Typography } from "@mui/material";

interface SetLimitModalProps {
  onClose: () => void;
  limit: number;
  notificationLimit: number;
  maxOrganizationLimit: number;
  onSave: ({ limit, notificationLimit }: { limit: number; notificationLimit: number }) => void;
}

export const SetLimitModal: FC<SetLimitModalProps> = ({
  onClose,
  limit,
  notificationLimit,
  maxOrganizationLimit,
  onSave,
}) => {
  const [limitValueString, setLimitValueString] = useState(limit.toFixed(2));
  const [notificationLimitValueString] = useState(notificationLimit.toFixed(2));

  const parseNumber = (value: string) => {
    let processedValue = value.replaceAll(" ", "");
    processedValue = processedValue.replaceAll(/[^0-9\-.]/g, "").trim();
    if (processedValue === "" || processedValue === "-") {
      return null;
    }
    return parseFloat(processedValue);
  };

  const newLimitValue = parseNumber(limitValueString);
  const newNotificationLimitValue = parseNumber(notificationLimitValueString);
  const isNewLimitLarge = newLimitValue ? newLimitValue > maxOrganizationLimit : false;
  const isNewLimitLessThanZero = newLimitValue ? newLimitValue <= 0 : false;
  const onSaveHandler = () => {
    onSave({
      limit: newLimitValue || 0,
      notificationLimit: Math.max(newNotificationLimitValue || 1, 1),
    });
  };

  return (
    <CustomModal onClose={onClose} width="min(1100px, 90vw)">
      <Stack gap={"30px"}>
        <Stack>
          <Typography variant="h2">{t`Usage limits`}</Typography>

          <Typography
            variant="body2"
            sx={{
              maxWidth: "600px",
              color: COLORS["text-label"],
            }}
          >
            {t`Manage your spend by configuring monthly spend limits. Notification emails will be sent to members of your organization with the "Admin" role.`}
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="h3">{t`Organization limit`}</Typography>
          <Typography
            variant="body2"
            sx={{
              maxWidth: "800px",
              color: COLORS["text-secondary"],
            }}
          >
            {t`The maximum usage Prohiring allows for your organization each month.`}{" "}
            <Link target="_blank" href="/increase-limit-request">
              {t`Request a limit increase`}
            </Link>
          </Typography>
          <Typography
            variant="h4"
            sx={{
              maxWidth: "600px",
              color: COLORS["text-secondary"],
            }}
          >
            ${maxOrganizationLimit.toFixed(2)}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          gap={"40px"}
          sx={{
            width: "100%",
          }}
        >
          <Stack
            gap={"5px"}
            sx={{
              width: "100%",
            }}
          >
            <Stack>
              <Typography variant="h3">{t`Set a monthly budget`}</Typography>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: "700px",
                  color: COLORS["text-secondary"],
                }}
              >
                {t`If your organization exceeds this budget in a given calendar month, AI requests will be rejected.`}
              </Typography>
            </Stack>

            <Stack>
              <TextField
                value={limitValueString}
                onChange={(e) => setLimitValueString(e.target.value)}
                label=""
                sx={{
                  width: "150px",
                }}
                error={isNewLimitLarge || isNewLimitLessThanZero}
                InputProps={{
                  endAdornment: <InputAdornment position="end">$</InputAdornment>,
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  color: COLORS["error"],
                  height: "1.4rem",
                }}
              >
                {isNewLimitLarge ? t`The limit exceeds the organization limit.` : ""}
                {isNewLimitLessThanZero ? t`The limit cannot be less than zero.` : ""}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          sx={{
            paddingTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onSaveHandler}
            disabled={
              (newLimitValue === limit && newNotificationLimitValue === notificationLimit) ||
              !newLimitValue ||
              isNewLimitLarge ||
              isNewLimitLessThanZero
            }
            sx={{
              width: "max-content",
              minWidth: "150px",
            }}
          >
            {t`Save`}
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
