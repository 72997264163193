import styled from "@emotion/styled";
import { GetTypeOfProperty } from "src/core/Utils/type.utils";

import InfoIcon from "@mui/icons-material/Info";
import { Components, ComponentsOverrides } from "@mui/material";

export const MultiValueOption = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

export const OptionsInfoIcon = styled(InfoIcon)({
  opacity: 0.7,
  ":hover": {
    opacity: 1,
  },
});

const chipStyle: ComponentsOverrides["MuiChip"] = {
  root: {
    fontFamily: "var(--font-family)",
    borderRadius: "4px",
    fontWeight: "500",
  },
};

export const customChip: GetTypeOfProperty<Components, "MuiChip"> = {
  styleOverrides: chipStyle,
};
