import { ComponentsOverrides } from "@mui/material";

import { menuShadow } from "../Menu/style";

const popoverStyleOverrides: ComponentsOverrides["MuiPopover"] = {
  paper: {
    boxShadow: menuShadow, // Custom shadow
    borderRadius: "8px",
  },
};

export const customPopover = {
  styleOverrides: popoverStyleOverrides,
};

const popperStyleOverrides: ComponentsOverrides["MuiPopper"] = {
  root: {
    ".MuiPaper-root": {
      boxShadow: menuShadow, // Custom shadow
      borderRadius: "8px",
    },
  },
};

export const customPopper = {
  styleOverrides: popperStyleOverrides,
};
