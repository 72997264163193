import { doc } from "firebase/firestore";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";

import { useAuth } from "../../Auth/useAuth";
import db from "../../Backend/firestore";
import { AccessContext } from "./accessContext";
import { emptyAccessSystemRole, superAdminAccess } from "./data";

export function useProviderAccess(): AccessContext {
  const auth = useAuth();

  const userId = auth.uid || "";

  const [userInfo, userInfoLoading] = useDocumentData(
    userId ? doc(db.collections.users, userId) : null,
  );
  const mySystemRoleId = userInfo?.accessSystemRole || "";
  const [allSystemRoles, loading] = useCollectionData(auth.uid ? db.collections.systemRoles : null);
  const mySystemRole =
    allSystemRoles?.find((role) => role.id === mySystemRoleId) || emptyAccessSystemRole;

  if (auth.isSuperAdmin) {
    return {
      loading: userInfoLoading || auth.loading || loading,
      ...superAdminAccess,
      allSystemRoles: allSystemRoles || [],
    };
  }

  return {
    loading: userInfoLoading || auth.loading || loading,
    ...mySystemRole,
    allSystemRoles: allSystemRoles || [],
  };
}
