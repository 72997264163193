import { forwardRef, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField, TextFieldProps } from "@mui/material";

export const PasswordTextField = forwardRef((props: TextFieldProps, ref) => {
  const [visibility, setVisibility] = useState(false);

  const type = visibility ? "text" : "password";
  const icon = visibility ? <Visibility /> : <VisibilityOff />;

  return (
    <TextField
      type={type}
      slotProps={{
        input: {
          endAdornment: <IconButton onClick={() => setVisibility(!visibility)}>{icon}</IconButton>,
        },
      }}
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error - ref is not in TextFieldProps
      ref={ref}
    />
  );
});
