import { t } from "@lingui/macro";
import { useNavigate } from "react-router-dom";
import { useQuery } from "src/core/Url/useQuery";
import { TabInfo } from "src/uiKit/Tabs/Tabs";

const settingsUrlParamName = "vacancySettingsTab";
const settingsUrlVacancyIdParamName = "vacancyIdSettings";

export type VacancySettingsTab = "access-settings" | "ai-search" | "source" | "tags";
export const useVacancySettingsUI = () => {
  const urlQuery = useQuery();
  const navigate = useNavigate();

  const vacancyId = urlQuery.get(settingsUrlVacancyIdParamName) || "";

  const tabs: TabInfo[] = [
    {
      label: t`Client Access`,
      id: "access-settings",
    },
    {
      label: t`Smart Search`,
      id: "ai-search",
    },
    {
      label: t`Sources`,
      id: "source",
    },
    {
      label: t`Tags`,
      id: "tags",
    },
  ];

  const activeTab = urlQuery.get(settingsUrlParamName) || "";
  const activeTabIndex: number = tabs.findIndex((tab) => tab.id === activeTab);

  const openSettings = (settingsTab: VacancySettingsTab, vacancyId: string) => {
    const newQuery = new URLSearchParams(urlQuery);
    newQuery.set(settingsUrlParamName, settingsTab);
    newQuery.set(settingsUrlVacancyIdParamName, vacancyId);
    navigate(`?${newQuery.toString()}`);
  };

  const closeSettings = () => {
    const newQuery = new URLSearchParams(urlQuery);
    newQuery.delete(settingsUrlParamName);
    newQuery.delete(settingsUrlVacancyIdParamName);
    navigate(`?${newQuery.toString()}`);
  };

  return {
    openSettings,
    closeSettings,
    openSettingsTabIndex: vacancyId && activeTabIndex !== -1 ? activeTabIndex : null,
    openSettingsTabId: vacancyId ? ((activeTab || null) as VacancySettingsTab | null) : null,
    tabs,
    vacancyId,
  };
};
