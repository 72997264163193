import { query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";

import { ClientCompany } from "@common/clientCompany.types";

import { useAuth } from "../../../../core/Auth/useAuth";
import db from "../../../../core/Backend/firestore";
import { ClientCompaniesContext } from "./clientCompaniesContext";

export function useProviderClientCompanies(): ClientCompaniesContext {
  const [clientCompanies, setClientCompanies] = useState<Record<string, ClientCompany | undefined>>(
    {},
  );
  const [clientCompaniesList, setClientCompaniesList] = useState<
    { data: ClientCompany; id: string }[]
  >([]);

  const auth = useAuth();

  const [allClientCompanies, loadingClientCompanies] = useCollection(
    auth.uid ? query(db.collections.clientCompanies) : null,
  );

  useEffect(() => {
    if (allClientCompanies && !loadingClientCompanies) {
      const clientCompanies = allClientCompanies.docs.reduce((acc, clientCompanyDoc) => {
        const clientCompanyInfo = clientCompanyDoc.data();

        return { ...acc, [clientCompanyDoc.id]: clientCompanyInfo };
      }, {});

      setClientCompaniesList(
        allClientCompanies.docs.map((clientCompanyDoc) => ({
          data: clientCompanyDoc.data(),
          id: clientCompanyDoc.id,
        })),
      );

      setClientCompanies(clientCompanies);
    }
  }, [allClientCompanies, loadingClientCompanies]);

  return {
    clientCompanies,
    clientCompaniesList,
  };
}
