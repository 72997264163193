import { GetTypeOfProperty } from "src/core/Utils/type.utils";
import { COLORS } from "src/uiKit/assets/styles/colors";

import { Components, ComponentsOverrides, ComponentsVariants } from "@mui/material";

export const menuShadow = "0px 4px 20px rgba(0, 0, 0, 0.2)";
export const menuRadius = "8px";

const menuStyleOverrides: ComponentsOverrides["MuiMenu"] = {
  paper: {
    borderRadius: menuRadius,
    boxShadow: menuShadow,
    backgroundColor: COLORS["bg-card"],
  },
};

const menuVariants: ComponentsVariants["MuiMenu"] = [
  {
    props: { variant: "menu" },
    style: {},
  },
];

export const customMenu: GetTypeOfProperty<Components, "MuiMenu"> = {
  styleOverrides: menuStyleOverrides,
  variants: menuVariants,
};
