import { Context, createContext } from "react";

import { SystemRole } from "@common/systemRole.types";

import { emptyAccessSystemRole } from "./data";

export interface AccessContext extends SystemRole {
  loading: boolean;
  allSystemRoles: SystemRole[];
}

export const accessContext: Context<AccessContext> = createContext<AccessContext>({
  loading: true,
  allSystemRoles: [],
  ...emptyAccessSystemRole,
});
